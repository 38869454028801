import React from 'react';

import './btn.scss';

export const Btn = ({
  text,
  mod,
  type,
  onClick,
  disabled,
  /* eslint-disable-next-line */
  ...rest
}) => {
  const handleOnClick = e => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <button
      className={`btn ${mod || ''}`}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={handleOnClick}
      disabled={disabled}
      /* eslint-disable-next-line */
      {...rest}
    >
      <span className="btn__text">{text}</span>
    </button>
  );
};
