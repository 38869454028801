import { connect } from 'react-redux';

import Acquire from './acquire';
import { fetchCurrencies } from '../../store/actions';

export default connect(
  null,
  {
    fetchCurrencies,
  }
)(Acquire);
