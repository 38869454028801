import React, { useState, useRef } from 'react';
import { get } from 'lodash';
import InputPlaceholderIconEye from './input-with-placeholder__icon-eye.svg';
import './input-with-placeholder.scss';

const InputWithPlaceholder = ({
  name,
  placeholder,
  type = 'text',
  onChange,
  onKeyDown,
  required = true,
  autoFocus,
}) => {
  const [isPasswordVisible, changePasswordVisibility] = useState(false);
  const [value, setValue] = useState('');
  const inputEl = useRef(null);
  const passwordInputType = isPasswordVisible ? 'text' : 'password';

  const handleOnChange = e => {
    e.stopPropagation();
    const inputValue = get(e, 'target.value', '');
    setValue(inputValue);
    onChange && onChange(inputValue);
  };

  const handlePlaceholderClick = e => {
    e.stopPropagation();
    inputEl.current.focus();
  };

  return (
    <div className={`input input--placeholder ${type || ''}`}>
      <input
        name={name}
        id={name}
        type={type === 'password' ? passwordInputType : type}
        onChange={handleOnChange}
        required={required}
        onKeyDown={onKeyDown}
        /* eslint-disable-next-line */
        autoFocus={autoFocus}
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        value={value}
        ref={inputEl}
      />

      {/* eslint-disable-next-line */}
      <div
        className="input__label-text"
        onClick={handlePlaceholderClick}
      >
        {placeholder}
      </div>

      {type === 'password' && (
        <button
          className="input__btn-pass"
          onClick={() => changePasswordVisibility(!isPasswordVisible)}
          type="button"
        >
          <img src={InputPlaceholderIconEye} alt="icon eye" />
        </button>
      )}
    </div>
  );
};

export default InputWithPlaceholder;
