import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import Div from '../clickable-div';
import { TRANSACTION_FEE_MAP } from '../../constants';
import './fees-tabs.scss';

export default ({
  fees,
  selectedFee,
  onFeeSelect
}) => {
  const feeCurrency = get(selectedFee, 'feeCurrency', '');
  const feeValue = get(selectedFee, 'fee', '');
  const selectedFeeType = get(selectedFee, 'feeType', '');

  return (
    <div className="intensity-tabs">
      <div className="intensity-tabs__tabs">
        {fees.map(fee => (
          <Div
            className={classNames('intensity-tabs__item', {
              'intensity-tabs__item--active': fee.feeType === selectedFeeType,
            })}
            onClick={() => onFeeSelect(fee)}
          >
            {TRANSACTION_FEE_MAP[fee.feeType]}
          </Div>
        ))}
      </div>
      <div className="intensity-tabs__total">{`Fee ${feeCurrency} ${feeValue}`}</div>
    </div>
  );
};
