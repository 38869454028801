// auth
export const AUTHORIZE = 'AUTHORIZE';
export const DELETE_AUTH = 'DELETE_AUTH';
export const SET_TWO_FACTOR_PARAMS = 'SET_TWO_FACTOR_PARAMS';
export const SET_QR_CODE_DATA = 'SET_QR_CODE_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';

// account
export const UDPATE_USER_ACCOUNT = 'UDPATE_USER_ACCOUNT';
export const UPDATE_OPERATIONS = 'UPDATE_OPERATIONS';

// acquire
export const SET_SUPPORTED_CURRENCIES = 'SET_SUPPORTED_CURRENCIES';
export const SET_CURRENCY_RATE = 'SET_CURRENCY_RATE';

// withdraw
export const SET_FEES = 'SET_FEES';
export const SHOW_WITHDRAW_CONFIRM_MODAL = 'SHOW_WITHDRAW_CONFIRM_MODAL';

export const PUSH_ERROR = 'PUSH_ERROR';

// app
export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_BACK_BUTTON_VISIBILITY = 'SET_BACK_BUTTON_VISIBILITY';
export const SET_PUSH_MESSAGE = 'SET_PUSH_MESSAGE';
export const SET_NOTICE = 'SET_NOTICE';
