import React, { Component } from 'react';
import { Form } from '../../modules/forms';
import H1 from '../../modules/h1';
import './acquire-form.scss';

/* eslint-disable-next-line */
export default class AcquireForm extends Component {
  componentDidMount() {
    /* eslint-disable-next-line */
    this.props.fetchCurrencies();
  }

  render() {
    // const { title } = this.props;
    // TODO need title props
    const title = 'Insert RUBL amount needed';
    return (
      <div className="acquire-form">
        <div className="acquire-form__title">
          <H1 text={title} />
        </div>

        <div className="acquire-form__body">
          <Form.Acquire />
        </div>
      </div>
    );
  }
}
