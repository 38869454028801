import React from 'react';

import Div from '../clickable-div';
import AccountHeader from '../account-header';
import AccountBalance from '../account-balance';
import Menu from '../menu';
import { Logo } from '../logo/logo';
import {
  RUBL_ACCOUNT_BALANCE,
  UNKNOWN_USER,
  DEPOSIT_PATH,
  BALANCE_PATH
} from '../../constants';
import { ReactComponent as BackArrow } from './back-arrow.svg';
import LogoImg from '../logo/logo.svg';
import './header.scss';

const Header = ({
  isUserLoggedIn,
  balance,
  desc = RUBL_ACCOUNT_BALANCE,
  name = UNKNOWN_USER,
  isBackButtonVisible,
  history,
}) => (
  <header className="header">
    <div className="container">
      <div className="header__top">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <Logo
              img={LogoImg}
              alt="Rubl"
            />
          </div>
          <div className="col-auto">
            <AccountHeader accountName={name} />
          </div>
        </div>
      </div>
      <div className="header__bottom">
        {isBackButtonVisible && (
          <Div className="back-button" onClick={() => history.push(BALANCE_PATH)}>
            <BackArrow className="back-button__arrow" />
            Back
          </Div>
        )}
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <AccountBalance
              balance={balance}
              desc={desc}
            />
          </div>
          <div className="col-auto">
            <Menu location={window.location.pathname} />
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
