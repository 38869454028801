import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OperationsList from './operations-list';

export default connect(
  state => ({
    operations: state.account.operations,
  })
)(withRouter(OperationsList));
