import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from './header';

export default connect(
  state => ({
    // isUserLoggedIn: state.auth.isLogged,
    isUserLoggedIn: true,
    balance: state.account.balance,
    desc: state.account.desc,
    name: state.account.displayName,
    isBackButtonVisible: state.app.isBackButtonVisible,
  }), null,
)(withRouter(Header));
