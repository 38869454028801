import React, { Component } from 'react';

import OperationsListMonth from './operations-list-month/operations-list-month';
import './operations-list.scss';
import { BALANCE_PATH, MONTH_MAP } from '../../constants';

export default class OperationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formattedOperations: {},
    };
  }

  componentDidMount() {
    const { operations } = this.props;
    if (operations.length > 0) {
      this.formatOperations();
    }
  }

  componentDidUpdate(prevProps) {
    const { operations } = this.props;
    if (prevProps.operations.length !== operations.length) {
      this.formatOperations();
    }
  }

  formatOperations = () => {
    const { operations } = this.props;
    const orderedOperations = {};
    operations.forEach(operation => {
      const month = new Date(operation.createdAt).getMonth();
      const monthName = MONTH_MAP[month];
      if (!orderedOperations[monthName]) {
        orderedOperations[monthName] = [];
        orderedOperations[monthName].push(operation);
      } else {
        orderedOperations[monthName].push(operation);
      }
    });
    this.setState({ formattedOperations: { ...orderedOperations } });
  };

  handleClick = item => {
    const { history } = this.props;
    history.push(`${BALANCE_PATH}/${item.id}`);
  };

  render() {
    const { formattedOperations } = this.state;
    return (
      <div className="operations-list">
        {
          Object.keys(formattedOperations).map(month => (
            <div className="operations-list__item" key={month}>
              <OperationsListMonth
                month={month}
                monthlyTransactions={formattedOperations[month]}
                onClick={this.handleClick}
              />
            </div>
          ))
        }
      </div>
    );
  }
}
