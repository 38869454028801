import React, { useEffect } from 'react';

import { ACCOUNT_NOT_VERIFIED } from '../../constants';

const Profile = ({ history, setNoticeType }) => {
  useEffect(() => {
    setNoticeType(ACCOUNT_NOT_VERIFIED);
    history.push('/notice');
  }, []);

  return <div />;
};

export default Profile;
