import React, { Component } from 'react';

import { Btn } from '../btn/btn';
import { FormItem } from '../form-item/form-item';
import { InputSelect } from '../input-select/input-select';
import { InputDesc } from '../input/input-desc';
import './form.scss';

export default class AcquireForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rublValue: '',
      fiatValue: '',
      currency: '',
      minRublValue: 500000
    };
  }

  componentDidUpdate(prevProps) {
    const { rate } = this.props;
    const { rublValue } = this.state;
    if (prevProps.rate !== rate) {
      this.handleRublChange(rublValue);
    }
  }

  handleRublChange = value => {
    const fiatValue = this.getFiatValue(value);
    this.setState({
      rublValue: value,
      fiatValue
    });
  }

  /* eslint-disable-next-line */
  getFiatValue = value => (value / this.props.rate).toFixed(2);

  handleFiatChange = value => {
    const { rate } = this.props;
    const rublValue = (value * rate).toFixed(2);
    this.setState({
      fiatValue: value,
      rublValue,
    });
  }

  handleSelectionChange = currency => {
    this.setState({ currency });
    // eslint-disable-next-line
    this.props.fetchRates(currency.code);
  }

  handleSubmit = e => {
    e.preventDefault();
    const { acquire, history } = this.props;
    const { rublValue, currency: { code } } = this.state;

    acquire(rublValue, code, history);
  }

  render() {
    const { rublValue, fiatValue, minRublValue } = this.state;
    const { currencies } = this.props;
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__block">
          <FormItem
            item={(
              <InputDesc
                name="rubl"
                placeholder="0"
                desc="RUBL"
                onChange={this.handleRublChange}
                value={rublValue}
                className="acquire-form-input"
                type="amount"
              />
            )}
            title="RUBL amount needed"
          />
        </div>

        <div className="form__block">
          <FormItem
            prompt
            item={(
              <InputSelect
                items={currencies}
                value={fiatValue}
                onChange={this.handleFiatChange}
                onSelectionChange={this.handleSelectionChange}
              />
            )}
            title="Fiat amount to acquire"
            bottom="Min. amount to acquire is ₽ 500 000"
          />
        </div>

        <div className="form__block">
          <Btn
            text="Request invoice"
            mod="btn--lg btn--block"
            type="submit"
            disabled={(!rublValue || !fiatValue || (+rublValue < minRublValue))}
          />
        </div>
      </form>
    );
  }
}
