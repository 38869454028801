import React from 'react';
import './prompt.scss';

export const Prompt = ({
  text,
}) => (
  <div className="prompt">
    <div className="prompt__title">&#9432;  To purchase RUBL you may transfer:</div>

    <ul className="prompt__list">
      <li className="prompt__item">
        <div className="prompt__icon">₽</div>
        <div className="prompt__text">Russian Rubles</div>
      </li>
      <li className="prompt__item">
        <div className="prompt__icon">$</div>
        <div className="prompt__text">US dollars</div>
      </li>
      <li className="prompt__item">
        <div className="prompt__icon">€</div>
        <div className="prompt__text">Euro</div>
      </li>
      <li className="prompt__item">
        <div className="prompt__icon">₣</div>
        <div className="prompt__text">Swiss Francs</div>
      </li>
    </ul>
  </div>
);
