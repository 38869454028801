// import { connect } from 'react-redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import Auth from './Auth';
import Header from './modules/header';
import HeaderUnauthorized from './modules/header-unauthorized';
import Footer from './modules/footer';
import Main from './modules/main';
import Spinner from './components/spinner';
import IntroPage from './pages/how-to-purchase-rubl';
import Acquire from './pages/acquire-form';
import Balance from './pages/balance';
import Login from './pages/login';
import SignUp from './pages/sign-up';
import Withdraw from './pages/withdraw';
import Deposit from './modules/deposit';
import Redeem from './pages/redeem';
import Profile from './pages/profile';
/* eslint-disable-next-line */
import Notice from './modules/notice';
import Push from './modules/push';
import Modal from './modules/modal';
import {
  LOGIN_PATH,
  TWO_FACTOR_PATH,
  INTRO_PATH,
  SIGNUP_PATH,
  ACQUIRE_PATH,
  BALANCE_PATH,
  WITHDRAW_PATH,
  DEPOSIT_PATH,
  REDEEM_PATH,
  VERIFY_PATH,
  NOTICE_PATH,
  PROFILE_PATH,
  FETCH_DATA_TIMEOUT,
} from './constants';
import TransactionDetails from './pages/transaction-details';
import { fetchAccountInfo } from './store/actions';

class App extends Component {
  constructor(props) {
    super(props);

    this.fetchAccountsInterval = null;
  }

  componentDidMount() {
    const { isAuthorized, fetchAccountInfoAction } = this.props;
    if (isAuthorized && !this.fetchAccountsInterval) {
      fetchAccountInfoAction();
      this.fetchAccountsInterval = setInterval(fetchAccountInfoAction, FETCH_DATA_TIMEOUT);
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthorized, fetchAccountInfoAction } = this.props;
    if (isAuthorized && !prevProps.isAuthorized) {
      if (!this.fetchAccountsInterval) {
        fetchAccountInfoAction();
        this.fetchAccountsInterval = setInterval(fetchAccountInfoAction, FETCH_DATA_TIMEOUT);
      }
    } else if (!isAuthorized && prevProps.isAuthorized) {
      if (this.fetchAccountsInterval) {
        clearInterval(this.fetchAccountsInterval);
        this.fetchAccountsInterval = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.fetchAccountsInterval) {
      clearInterval(this.fetchAccountsInterval);
      this.fetchAccountsInterval = null;
    }
  }

  render() {
    const { isAuthorized, shouldShowModal } = this.props;
    return (
      <Auth>
        {isAuthorized ? <Header /> : <HeaderUnauthorized />}
        <Main centerHorizontal>
          <Switch>
            <Route exact path={LOGIN_PATH} component={Login.Basic} />
            <Route exact path={TWO_FACTOR_PATH} component={Login.TwoFactor} />
            <Route exact path={SIGNUP_PATH} component={SignUp} />
            <Route exact path={['/', INTRO_PATH]} component={IntroPage} />
            <Route exact path={ACQUIRE_PATH} component={Acquire} />
            <Route exact path={BALANCE_PATH} component={Balance} />
            <Route exact path={`${BALANCE_PATH}/:transactionId`} component={TransactionDetails} />
            <Route exact path={WITHDRAW_PATH} component={Withdraw} />
            <Route exact path={DEPOSIT_PATH} component={Deposit} />
            <Route exact path={REDEEM_PATH} component={Redeem} />
            <Route exact path={PROFILE_PATH} component={Profile} />
            <Route exact path={[VERIFY_PATH, `${VERIFY_PATH}/email`, NOTICE_PATH]} component={Notice} />
          </Switch>
        </Main>
        <Footer />
        <Spinner />
        <Push />
        {shouldShowModal && <Modal />}
      </Auth>
    );
  }
}

export default connect(
  state => ({
    isAuthorized: state.auth.isAuthorized,
    shouldShowModal: state.app.shouldShowConfirmWithdrawModal,
  }),
  {
    fetchAccountInfoAction: fetchAccountInfo,
  }
)(withRouter(App));
