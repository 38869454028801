import React from 'react';
import classNames from 'classnames';

import './main.scss';
import { DEPOSIT_PATH } from '../../constants';

const Main = ({
  center,
  centerHorizontal,
  centerVertical,
  paddingAcquire,
  children,
}) => (
  <div
    className={classNames('main', {
      'main--center-horizontal': centerHorizontal || center,
      'main--center-vertical': centerVertical || center,
      'main--padding-acquire': window.location.pathname === 'acquire',
    })}
  >
    {children}
  </div>
);

export default Main;
