import React from 'react';
import Authorization from '../../modules/authorization';
import { Form } from '../../modules/forms';
import './login.scss';

const Login = () => (
  <Authorization
    type="login"
    menu
    body={<Form.Login />}
  />
);

const TwoFactorLogin = () => (
  <Authorization
    title="Two-Factor Authentication"
    subtitle={(
      <>
        The first step is&nbsp;to&nbsp;download the Google Authenticator app
        for your Android or&nbsp;iOS device. If&nbsp;you need help getting started, please see
        <a href="link" target="_blank">{' Google’s Support Page'}</a>
        .
        {' '}
      </>
    )}
    body={<Form.TwoFactorLogin />}
  />
);

const Logins = {
  Basic: Login,
  TwoFactor: TwoFactorLogin,
};

export default Logins;
