import axios from 'axios';

import {
  SET_BACK_BUTTON_VISIBILITY,
  SET_PUSH_MESSAGE,
  SET_NOTICE,
  VERIFIED_EMAIL,
  LOGIN_PATH,
  SET_LOADING,
} from '../../constants';
import {
  AUTH_API_BASE,
  EMAIL_VERIFICATION_URL
} from '../../api/urls';

export const setBackButtonVisibility = isVisible => dispatch => {
  dispatch({
    type: SET_BACK_BUTTON_VISIBILITY,
    payload: isVisible,
  });
};

export const setPushMessage = (message = '') => dispatch => {
  dispatch({
    type: SET_PUSH_MESSAGE,
    payload: message
  });
};

export const setNoticeType = (type = '', email) => dispatch => {
  dispatch({
    type: SET_NOTICE,
    payload: {
      type,
      email,
    },
  });
};

export const checkVerifiedEmail = (token, history) => async dispatch => {
  try {
    const response = await axios.get(`${AUTH_API_BASE}${EMAIL_VERIFICATION_URL}?token=${token}`);

    if (response.status === 200) {
      dispatch(setNoticeType(VERIFIED_EMAIL));
    }
  } catch (e) {
    history.push(LOGIN_PATH);
  }
};

export const setAppLoading = (isLoading = false) => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: isLoading,
  });
};
