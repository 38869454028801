import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import './input.scss';

export const InputDesc = ({
  name,
  placeholder,
  desc,
  onChange,
  value,
  className,
  limit = 50000000,
  type = 'text'
}) => {
  const handleOnChange = e => {
    const { target: { value: inputValue } } = e;
    // console.log(`VALUE: ${inputValue}`);
    if (type === 'amount') {
      const replacedVal = inputValue.replace(/\s/g, '');
      const val = replacedVal === '.' ? 0 : replacedVal;
      onChange && onChange(val);
    } else {
      onChange && onChange(inputValue);
    }
  };

  return (
    <div
      className={classNames('input input--desc', {
        [className]: !!classNames,
      })}
    >
      {type === 'amount' && (
        <NumberFormat
          value={value}
          name={name}
          id={name}
          onChange={handleOnChange}
          placeholder={placeholder}
          thousandSeparator=" "
          decimalScale={2}
          isNumericString="true"
          isAllowed={values => {
            const { floatValue, formattedValue } = values;
            if (formattedValue.startsWith('0') && !formattedValue.startsWith('0.')) {
              return false;
            }
            return floatValue === undefined || floatValue <= limit;
          }}
        />
      )}
      {type !== 'amount' && (
        <input
          name={name}
          id={name}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          type={type}
        />
      )}
      <span className="input__desc">{desc}</span>
    </div>
  );
};
