import React from 'react';
import classNames from 'classnames';

import './clickable-div.scss';

const ClickableDiv = ({
  className,
  onClick,
  tabIndex = 0,
  role = 'button',
  children,
}) => {
  const handleOnClick = e => {
    e && e.stopPropagation();
    onClick && onClick();
  };

  const handleOnKeyDown = e => {
    e && e.stopPropagation();
    if (e.keyCode === 13) {
      handleOnClick();
    }
  };

  return (
    /* eslint-disable-next-line */
    <div
      className={classNames('clickable-div', {
        [className]: !!className
      })}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      role={role}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
};

export default ClickableDiv;
