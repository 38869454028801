import React, { useState } from 'react';
import { FormItem } from '../form-item/form-item';
import InputWithPlaceholder from '../input-with-placeholder';
import { Btn } from '../btn/btn';
import './form.scss';
import QrToken from '../qr-token';
import { handleValidation } from '../../store/actions';

const TwoFactorLoginForm = ({
  secondsQrValid,
  qrCode,
  qrDescription,
  enableTwoFactor,
  history,
}) => {
  const [codeValue, setCodeValue] = useState('');

  const handleOnChange = value => {
    const isValid = handleValidation('^(\\d{6})', value);
    if (isValid) {
      setCodeValue(value);
    } else {
      setCodeValue('');
    }
  };

  const handleSubmit = e => {
    e && e.preventDefault();
    enableTwoFactor(codeValue, history);
  };

  const handleKeyDown = e => {
    e && e.stopPropagation();
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__block">
        <FormItem>
          <QrToken
            qrCode={qrCode}
            qrDescription={qrDescription}
          />
        </FormItem>
      </div>

      <div className="form__block">
        <FormItem>
          <InputWithPlaceholder
            name="googleCode"
            placeholder="Google Authenticator Code"
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            type="number"
          />
        </FormItem>
      </div>

      <div className="form__block">
        <Btn
          text="Enable"
          mod="btn--lg btn--block"
          type="submit"
          disabled={!codeValue}
        />
      </div>
    </form>
  );
};

export default TwoFactorLoginForm;
