import React, { useState } from 'react';
import classNames from 'classnames';
import './select.scss';

export default ({
  items,
  selectedItem,
  onSelect,
}) => {
  const [isOpen, switchOpenState] = useState(false);

  const toggleSelection = e => {
    e && e.stopPropagation();
    switchOpenState(!isOpen);
  };

  const handleOnKeyDown = (e, item, onClick) => {
    if (e.keyCode === 13) {
      toggleSelection(e, item);
    }
  };

  const onItemSelect = (e, item) => {
    e && e.stopPropagation();
    switchOpenState(false);
    onSelect(item);
  };

  const SelectItem = ({ item, onClick }) => (
    <div
      className="select__item"
      onClick={e => onClick(e, item)}
      onKeyDown={e => handleOnKeyDown(e, item, onClick)}
      role="button"
      tabIndex={0}
    >
      <div className="select__icon">{item.label}</div>
      <div className="select__text">{item.name}</div>
    </div>
  );

  return (
    <div
      className={classNames('select', {
        'select--open': isOpen,
      })}
    >
      <div className="select__header">
        <SelectItem item={selectedItem} onClick={toggleSelection} />
      </div>
      {items.length > 0 && (
        <div className="select__list">
          {items.map(item => (
            <SelectItem
              item={item}
              onClick={onItemSelect}
              key={item.label + item.name}
            />
          ))}
        </div>
      )}
    </div>
  );
};
