import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reducer from './store/reducer';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const reduxHOC = process.env === 'development' ?
  composeWithDevTools(applyMiddleware(thunkMiddleware)) :
  applyMiddleware(thunkMiddleware);

const store = createStore(reducer, reduxHOC);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
