import axios from 'axios';
import { get, isNil } from 'lodash';
// import QRCode from 'qrcode';

import { fetchAccountInfo } from './account';
import { setPushMessage, setNoticeType, setAppLoading } from './app';
import {
  API_BASE,
  AUTH_API_BASE,
  TWO_FACTOR_LOGIN_URL,
  TWO_FACTOR_ENABLE_URL,
  TWO_FACTOR_ENABLE_CONFIRM_URL,
  LOGIN_URL,
  REGISTER_URL,
} from '../../api/urls';
import {
  AUTHORIZE,
  // DELETE_AUTH,
  CLEAR_DATA,
  SET_TWO_FACTOR_PARAMS,
  SET_QR_CODE_DATA,
} from '../../constants/actions';
import {
  STATUS_200,
  STATUS_202,
  UNAUTHORIZED_401,
  ILLEGAL_ARGUMENTS_400,
  LOGIN_PATH,
  INTRO_PATH,
  TWO_FACTOR_PATH,
  AUTH_ERROR,
  STANDART_ERROR,
  ILLEGAL_ARGUMENTS_ERROR,
  VERIFY_EMAIL,
  VERIFY_PATH, TOKEN_EXPIRED,
} from '../../constants';

export const deleteAuthorization = cb => dispatch => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('expirationDate');

  dispatch({
    // type: DELETE_AUTH,
    type: CLEAR_DATA,
  });

  cb && cb();
};

export const setBasicAxiosConfig = history => dispatch => {
  axios.defaults.headers.common['cache-control'] = 'no-cache';
  axios.interceptors.response.use(
    response => (response),
    err => {
      dispatch(setAppLoading(false));
      const status = get(err, 'response.status', 401);

      // eslint-disable-next-line
      const pathname = window.location.pathname;
      if (status === UNAUTHORIZED_401) {
        dispatch(deleteAuthorization(() => history.push(LOGIN_PATH)));
        if (pathname === LOGIN_PATH) {
          dispatch(setPushMessage(AUTH_ERROR));
        } else {
          dispatch(setPushMessage(TOKEN_EXPIRED));
        }
      } else if (status === ILLEGAL_ARGUMENTS_400) {
        if (pathname === LOGIN_PATH) {
          dispatch(setPushMessage('Validation error!'));
        } else {
          const desc = get(err, 'response.data.description', 'Validation error!');
          dispatch(setPushMessage(desc));
        }
      } else {
        dispatch(setPushMessage(STANDART_ERROR));
      }

      return Promise.reject(err.response);
    }
  );
};

const configureAxios = token => {
  // axios.defaults.baseURL = API_BASE; // TODO: uncommen when URL will be the same
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const updateLocalStorage = (data = {}) => (dispatch, getState) => {
  let { jwt, untilValid, needTwoFactor } = data;
  const { auth } = getState();
  if (!jwt) {
    jwt = get(auth, 'jwt', 0);
  }

  if (!untilValid) {
    untilValid = get(auth, 'expirationDate', 0);
  }

  if (isNil(needTwoFactor)) {
    needTwoFactor = get(auth, 'needTwoFactor', false);
  }

  window.localStorage.setItem('token', jwt);
  window.localStorage.setItem('expirationDate', untilValid);
  window.localStorage.setItem('needTwoFactor', needTwoFactor);
};

export const authUser = (login, password, history) => async dispatch => {
  const payload = {
    login,
    password,
  };

  try {
    const response = await axios.post(`${AUTH_API_BASE}${LOGIN_URL}`, payload);

    const shouldSetUpTwoFactorAuth = !get(response, 'data.needTwoFactor', false);
    const status = get(response, 'status', 0);

    if (status === STATUS_200 && shouldSetUpTwoFactorAuth) {
      const token = get(response, 'data.jwt', '');
      const expirationDate = get(response, 'data.untilValid', 0);
      configureAxios(token);

      const qrCodeResponse = await axios.post(`${AUTH_API_BASE}${TWO_FACTOR_ENABLE_URL}`, {
        twoFactorMethod: 'GOOGLE_AUTH',
      });

      const qrStatus = get(qrCodeResponse, 'status', 0);

      if (qrStatus === STATUS_200) {
        const {
          id,
          secondsFormValid,
          payload: responsePayload,
          desc,
        } = get(qrCodeResponse, 'data', {});

        dispatch({
          type: SET_QR_CODE_DATA,
          payload: {
            id,
            secondsQrValid: secondsFormValid,
            // qrCode: generatedQr,
            qrCode: `data:image/png;base64, ${responsePayload}`,
            qrDescription: desc,
          }
        });

        dispatch({
          type: AUTHORIZE,
          payload: {
            ...response.data,
            expirationDate,
          },
        });

        history.push(TWO_FACTOR_PATH);
      } else {
        dispatch(setPushMessage(STANDART_ERROR));
        history.push(LOGIN_PATH);
      }
    } else if (status === STATUS_202) {
      const { id, secondsFormValid } = get(response, 'data.twoFactorInfo', {});

      dispatch({
        type: SET_TWO_FACTOR_PARAMS,
        payload: {
          twoFactorId: id,
          secondsTwoFactorFormValid: secondsFormValid,
        },
      });
    } else {
      dispatch(setPushMessage(STANDART_ERROR));
    }
  } catch (e) {
    console.error(e);
  }
};

export const authTwoFactor = (code, history) => async (dispatch, getState) => {
  const twoFactorId = get(getState(), 'auth.twoFactorId', 0);

  const payload = {
    code,
    id: twoFactorId,
  };

  const response = await axios.post(`${AUTH_API_BASE}${TWO_FACTOR_LOGIN_URL}`, payload);

  const status = get(response, 'status', 0);
  if (status === STATUS_200) {
    axios.defaults.baseURL = API_BASE; // TODO: REMOVE WHEN URL WILL BE ONE FOR ALL !!!!
    dispatch(updateLocalStorage(response.data));

    const token = get(response, 'data.jwt', 0);
    const expirationDate = get(response, 'data.untilValid', 0);
    configureAxios(token);

    dispatch({
      type: AUTHORIZE,
      payload: {
        ...response.data,
        isAuthorized: true,
        expirationDate,
      },
    });

    history.push(INTRO_PATH);
  } else {
    history.push(LOGIN_PATH);
  }
};

export const enableTwoFactor = (code, history) => async (dispatch, getState) => {
  const qrId = get(getState(), 'auth.qrInfo.id', '');
  if (!qrId) {
    dispatch(deleteAuthorization());
    history.push(LOGIN_URL); // redirecting back to login
  }

  const payload = {
    code,
    id: qrId,
  };

  try {
    const response = await axios.post(`${AUTH_API_BASE}${TWO_FACTOR_ENABLE_CONFIRM_URL}`, payload);
    if (response.status === STATUS_200) {
      axios.defaults.baseURL = API_BASE; // TODO: REMOVE WHEN URL WILL BE ONE FOR ALL !!!!
      dispatch(updateLocalStorage());

      dispatch({
        type: AUTHORIZE,
        payload: {
          isAuthorized: true,
        }
      });

      history.push(INTRO_PATH);
    } else {
      dispatch(setPushMessage(STANDART_ERROR));
      dispatch(deleteAuthorization(() => history.push(LOGIN_PATH))); // clearing auth and pushig to login path
    }
  } catch (e) {
    dispatch(setPushMessage(STANDART_ERROR));
    dispatch(deleteAuthorization(() => history.push(LOGIN_PATH))); // clearing auth and pushig to login path
  }
};

export const signupUser = (credentials, history) => async dispatch => {
  const {
    firstName, lastName, email, password
  } = credentials;

  const payload = {
    login: email,
    firstName,
    lastName,
    password,
  };

  const response = await axios.post(`${AUTH_API_BASE}${REGISTER_URL}`, payload);

  if (response.status === STATUS_200) {
    dispatch(setNoticeType(VERIFY_EMAIL, email));
    history.push(VERIFY_PATH);
  }
};

export const repopulateAuth = () => dispatch => {
  const token = window.localStorage.getItem('token');
  const expirationDate = window.localStorage.getItem('expirationDate');
  const needTwoFactor = window.localStorage.getItem('needTwoFactor');

  axios.defaults.baseURL = API_BASE; // TODO: REMOVE WHEN URL WILL BE ONE FOR ALL !!!!
  configureAxios(token);

  dispatch(fetchAccountInfo());

  dispatch({
    type: AUTHORIZE,
    payload: {
      token,
      expirationDate,
      needTwoFactor,
      isAuthorized: true,
    }
  });
};
