import { connect } from 'react-redux';

import Redeem from './redeem';
import { fetchCurrencies, fetchRates } from '../../store/actions';

export default connect(
  null,
  {
    fetchCurrencies,
    fetchRates
  }
)(Redeem);
