export const API_BASE = 'https://rubl.coinbank.world/api';
export const AUTH_API_BASE = 'https://rubl.coinbank.world/api'; // TODO: remove // only for auth
export const LOGIN_URL = '/login';
export const TWO_FACTOR_LOGIN_URL = '/login/two-factor';
export const REGISTER_URL = '/registration';
export const EMAIL_VERIFICATION_URL = '/v1/security/verify';

export const ACCOUNTS_URL = '/v1/accounts';
export const TWO_FACTOR_ENABLE_URL = '/v1/security/twofactor/enable';
export const TWO_FACTOR_ENABLE_CONFIRM_URL = '/v1/security/twofactor/enable/confirm';
export const PROFILE_URL = '/v1/profile';
export const ACQUIRE_URL = '/v1/operations/acquire';
export const REDEEM_URL = '/v1/operations/redeem';
export const SUPPORTED_CURRENCIES = '/v1/rates/supported/currencies';
export const CURRENCY_RATE = '/v1/rates';
export const FEES_URL = '/v1/transactions/fees';
export const WITHDRAW_URL = '/v1/operations/withdraw';
export const CONFIRM_WITHDRAW_URL = '/v1/security/twofactor/verify';

// balance
export const BALANCE_URL = '/v1/transactions/all';
