import React from 'react';
import './footer.scss';
import { MenuFooter } from '../menu-footer/menu-footer';
import { SocialIcon } from '../social-icon/social-icon';
import { Logo } from '../logo/logo';
import { PoweredByGp7 } from '../powered-by-gp7/powered-by-gp7';
import LogoImgDark from '../logo/logo--dark.svg';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__top">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <div className="footer__logos">
              <PoweredByGp7 />
            </div>
          </div>

          <div className="col-auto">
            <MenuFooter />
          </div>
        </div>
      </div>

      <div className="footer__bottom">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <p className="footer__copy">© 2021 RUBL All rights reserved</p>
          </div>
          <div className="col-auto">
            <SocialIcon />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
