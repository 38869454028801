import React from 'react';
import './menu-footer.scss';

export const MenuFooter = () => (
  <nav className="menu-footer">
    <ul className="menu-footer__list">
      <li className="menu-footer__item">
        <a
          className="menu-footer__link"
          href="https://docs.gp7.app/legal/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </li>

      <li className="menu-footer__item">
        <a
          className="menu-footer__link"
          href="https://docs.gp7.app/legal/cookies"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
      </li>

      <li className="menu-footer__item">
        <a
          className="menu-footer__link"
          href="https://docs.gp7.app/legal/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </li>
    </ul>
  </nav>
);
