import React from 'react';
import './social-icon.scss';
import iconTW from './social-icon__tw.svg';
import iconTel from './social-icon__tel.svg';
import iconMail from './social-icon__mail.svg';

export const SocialIcon = () => (
  <ul className="social-icon">
    <li className="social-icon__item">
      <a className="social-icon__link" href="https://twitter.com/GP7_app" target="_blank" rel="noopener noreferrer">
        <img src={iconTW} alt="" />
      </a>
    </li>
    <li className="social-icon__item">
      <a className="social-icon__link" href="https://t.me/RUBL_wallet" target="_blank" rel="noopener noreferrer">
        <img src={iconTel} alt="" />
      </a>
    </li>
    <li className="social-icon__item">
      <a className="social-icon__link" href="mailto:support@rubl.org" target="_blank" rel="noopener noreferrer">
        <img src={iconMail} alt="" />
      </a>
    </li>
  </ul>
);
