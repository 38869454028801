import React from 'react';
import classNames from 'classnames';

import './input.scss';

// export type InputProps = {
//   name: string,
//   placeholder: string
// }

// export const Input = (props: InputProps) => {
const Input = ({
  name,
  placeholder,
  type,
  onClick,
  className,
  onChange,
}) => {
  const handleOnChange = e => {
    /* eslint-disable-next-line */
    const value = e.target.value;
    onChange && onChange(value);
  };

  return (
    <div
      className={classNames('input', {
        'input--pass': type === 'password',
        [className]: !!className,
      })}
    >
      <input
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={handleOnChange}
      />

      {type === 'password' && (
        <button className="input__btn-pass" onClick={onClick} type="button">
          <img src="../../assets/images/icon--eye.svg" alt="icon eye" />
        </button>
      )}
    </div>
  );
};

export default Input;
