import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import './menu.scss';

const paths = [
  {
    href: '/acquire',
    title: 'Acquire',
  },
  {
    href: '/redeem',
    title: 'Redeem',
  },
  {
    href: '/balance',
    title: 'Balance',
  },
  {
    href: '/profile',
    title: 'Profile',
  },
];

const Menu = ({ location, history }) => {
  const onClick = (e, href) => {
    e.stopPropagation();
    history.push(href);
  };

  const handleOnKeyDown = (e, href) => {
    if (e.keyCode === 13) {
      onClick(e, href);
    }
  };

  return (
    <nav className="menu">
      <ul className="menu__list">
        {
          paths.map(({ href, title }) => (
            <li
              className={classNames('menu__item', {
                'menu__item--active': location === href
              })}
              key={href + title}
            >
              <div
                className="menu__link"
                onClick={e => onClick(e, href)}
                onKeyDown={e => handleOnKeyDown(e, href)}
                tabIndex={0}
                role="button"
              >
                {title}
              </div>
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

export default withRouter(Menu);
