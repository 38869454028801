import {
  UDPATE_USER_ACCOUNT,
  UPDATE_OPERATIONS,
  SET_FEES,
  CLEAR_DATA,
} from '../../constants/actions';

const initState = {
  address: '',
  balance: 0,
  id: 0,
  isLoading: false,
  login: '',
  email: '',
  displayName: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  verified: false,
  kycVerificationStatus: '',
  status: '',
  role: '',
  operations: [],
  openTransaction: {},
};

export default (state = initState, { type, payload = {} }) => {
  switch (type) {
    case UDPATE_USER_ACCOUNT:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_OPERATIONS:
      return {
        ...state,
        operations: [...payload],
      };
    case SET_FEES:
      return {
        ...state,
        fees: [...Object.values(payload)],
      };
    case CLEAR_DATA:
      return {
        ...initState
      };
    default:
      return state;
  }
};
