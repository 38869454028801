import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UserMenu from './user-menu';
import { deleteAuthorization } from '../../store/actions';

export default connect(
  state => ({
    name: state.account.displayName,
  }),
  {
    deleteAuthorization,
  }
)(withRouter(UserMenu));
