import React from 'react';
import { Checkbox } from '../checkbox';
import './checkbox-text.scss';

export const CheckboxText = ({
  text,
  onChange,
}) => (
  <div className="checkbox-text">
    <div className="checkbox-text__checkbox">
      <Checkbox onChange={onChange} />
    </div>

    <div className="checkbox-text__text">
      {text}
    </div>
  </div>
);
