import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import LoginForm from './login-form';
import SignUpForm from './signup-form';
import TwoFactorLoginForm from './two-factor-login-form';
import AcquireForm from './acquire-form';
import RedeemForm from './redeem-form';
import WithdrawForm from './withdraw-form';
import ConfirmWithdrawModalForm from './confirm-withdraw-modal-form';
import PasswordResetForm from './password-reset-form';
import {
  authUser,
  authTwoFactor,
  enableTwoFactor,
  signupUser,
  acquire,
  redeem,
  fetchRates,
  fetchFees,
  withdraw,
  toggleVerifyWithdraw,
} from '../../store/actions';

const ConnectedLoginForm = connect(
  state => ({
    twoFactorId: get(state, 'auth.twoFactorId', 0),
  }),
  {
    authUser,
    authTwoFactor,
  },
)(withRouter(LoginForm));

const ConnectedEnableTwoFactorForm = connect(
  state => {
    const {
      id,
      secondsQrValid,
      qrCode,
      qrDescription,
    } = get(state, 'auth.qrInfo', {});

    return {
      id,
      secondsQrValid,
      qrCode,
      qrDescription,
    };
  },
  {
    enableTwoFactor
  }
)(withRouter(TwoFactorLoginForm));

const ConnectedSignUpForm = connect(
  null,
  {
    signupUser
  }
)(withRouter(SignUpForm));

const ConnectedAcquireForm = connect(
  state => ({
    currencies: state.app.currencies,
    rate: get(state, 'app.rate.rate', 1),
  }),
  {
    acquire,
    fetchRates,
  }
)(withRouter(AcquireForm));

const ConnectedRedeemForm = connect(
  state => ({
    balance: state.account.balance,
    currencies: state.app.currencies,
    rate: get(state, 'app.rate.rate', 1),
  }),
  {
    redeem,
    fetchRates,
  }
)(withRouter(RedeemForm));

const ConnectedWithdrawForm = connect(
  state => ({
    balance: state.account.balance,
    fees: state.account.fees,
  }),
  {
    fetchFees,
    withdraw,
    toggleVerifyWithdraw,
  }
)(withRouter(WithdrawForm));

export const Form = {
  Login: ConnectedLoginForm,
  SignUp: ConnectedSignUpForm,
  TwoFactorLogin: ConnectedEnableTwoFactorForm,
  Acquire: ConnectedAcquireForm,
  Redeem: ConnectedRedeemForm,
  Withdraw: ConnectedWithdrawForm,
  ConfirmWithdrawModal: ConfirmWithdrawModalForm,
  PasswordReset: PasswordResetForm,
};
