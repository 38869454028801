import React, { useEffect } from 'react';

import CryptoAddress from '../crypto-address';
import H1 from '../h1';
import './deposit.scss';

const Deposit = ({
  address,
  addressQr,
  setBackButtonVisibility
}) => {
  useEffect(() => {
    setBackButtonVisibility(true);
    return () => setBackButtonVisibility(false);
  }, []);

  return (
    <div className="deposit">
      <H1 text="Deposit RUBL" className="deposit__header" />
      <img src={addressQr} className="deposit__qr" alt="address qr" />
      <div className="deposit__info">
        To deposit RUBL scan this QR-code via
        <br />
        your wallet app or copy
        <b> address </b>
        below
      </div>
      <CryptoAddress address={address} />
    </div>
  );
};

export default Deposit;
