export const CURRENCY_MAP = {
  RUB: {
    name: 'Ruble',
    label: '₽',
  },
  USD: {
    name: 'US Dollar',
    label: '$',
  },
  EUR: {
    name: 'Euro',
    label: '€',
  },
  CHF: {
    name: 'Swiss Franc',
    label: '₣',
  },
};

export const MONTH_MAP = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
};

export const TRANSACTION_FEE_MAP = {
  SLOW: 'Low',
  AVERAGE: 'Medium',
  FAST: 'Fast',
};
