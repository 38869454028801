import React from 'react';
import './logo.scss';

export const Logo = ({
  img,
  alt,
  mod,
}) => (
  <a className={`logo ${mod || ''}`} href="/intro">
    <img src={img} alt={alt} />
  </a>
);
