import { connect } from 'react-redux';

import Modal from './modal';
import { submitCode, toggleVerifyWithdraw } from '../../store/actions';

export default connect(
  null,
  {
    submitCode,
    toggleVerifyWithdraw
  }
)(Modal);
