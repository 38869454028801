import React, { useEffect } from 'react';
import classNames from 'classnames';
import './push.scss';
import NoticeSmImgClose from './push__close.svg';
import NoticeSmImgError from './push__error.svg';
import { PUSH_TIMEOUT } from '../../constants';

const Push = ({
  message,
  setPushMessage
}) => {
  let timer = null;
  let localMessage = '';

  const clearTimer = () => {
    timer && clearTimeout(timer);
    timer = null;
  };

  useEffect(() => {
    timer = setTimeout(() => setPushMessage(''), PUSH_TIMEOUT);
    return () => clearTimer();
  }, []);

  useEffect(() => {
    if (message && message !== localMessage) {
      localMessage = message;
      clearTimer();
      timer = setTimeout(() => setPushMessage(''), PUSH_TIMEOUT);
    } else if (!message && localMessage) {
      clearTimer();
    }
  });

  const clearPush = () => {
    setPushMessage('');
  };

  return (
    <div
      className={classNames('push', {
        'push--visible': !!message,
      })}
    >
      <div className="push__body">
        <img src={NoticeSmImgError} alt="Close" className="push__icon" />
        <span className="push__text">{message}</span>
      </div>

      <button type="button" onClick={clearPush}>
        <img src={NoticeSmImgClose} alt="Close" />
      </button>
    </div>
  );
};

export default Push;
