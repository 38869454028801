/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Btn } from '../btn/btn';
import { FormItem } from '../form-item/form-item';
import { InputText } from '../input-text/input-text';
import Input from '../input';
import FeesTabs from '../fees-tabs';
import './form.scss';
import { BALANCE_PATH } from '../../constants';
import {handleValidation} from "../../store/actions";

export default ({
  balance,
  fetchFees,
  fees,
  withdraw,
  history,
  toggleVerifyWithdraw,
}) => {
  const [selectedFee, setSelectedFee] = useState({});
  const [withdrawValue, setWithdrawAmount] = useState('');
  const [address, setAddress] = useState('');
  const [validAddress, setValidAddress] = useState(false);
  const [validAmount, setValidAmount] = useState(false);

  useEffect(() => {
    fetchFees();
  }, []);

  useEffect(() => {
    if (isEmpty(selectedFee) && fees && fees.length > 0) {
      setSelectedFee(fees[0]);
    }
  });

  const updateAddress = val => {
    const isValid = handleValidation('^(0x([A-Fa-f0-9]{40})$)', val);
    setAddress(val);
    if (isValid) {
      setValidAddress(true);
    } else {
      setValidAddress(false);
    }
  };

  const updateWithdrawAmount = val => {

    if (val <= balance) {
      setValidAmount(true);
    } else {
      setValidAmount(false);
    }
    if (val === '') {
      setValidAmount(false);
    }

    setWithdrawAmount(val);
  };

  const handleSubmit = e => {
    e && e.preventDefault();
    if (!address || !withdrawValue) return;

    const successCallBack = () => withdraw({
      rublValue: withdrawValue,
      address,
      feeType: selectedFee.feeType,
    }, () => history.push(BALANCE_PATH));

    toggleVerifyWithdraw(successCallBack);
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__block">
        <FormItem
          title="Amount"
          secondTitle="Balance"
          bottomError={withdrawValue && !validAmount && `Available only ${balance} RUBL`}
        >
          <InputText
            placeholder={0}
            name="withdraw amount"
            description="RUBL"
            additionalInfo={balance}
            onChange={updateWithdrawAmount}
            className="withdraw-form-input"
            type="amount"
          />
        </FormItem>
      </div>

      <div className="form__block">
        <FormItem
            title="Address"
            bottomVerified={validAddress && 'Valid address'}
            bottomError={address.length > 0 && !validAddress && 'Incorrect Ethereum address'}
        >
          <Input className="input-address" onChange={updateAddress} />
        </FormItem>
      </div>

      {fees && (
        <div className="form__block">
          <FormItem
            item={(
              <FeesTabs
                selectedFee={selectedFee}
                onFeeSelect={setSelectedFee}
              />
            )}
          />
        </div>
      )}

      <div className="form__block">
        <Btn
          text="Withdraw"
          mod="btn--lg btn--block"
          type="submit"
          disabled={!validAmount || !validAddress}
        />
      </div>
    </form>
  )
};
