import React, { useEffect } from 'react';
import H1 from '../../modules/h1';
import './how-to-purchase-rubl.scss';
import HowToPpurchaseRublImg1 from './icon96-01.svg';
import HowToPpurchaseRublImg2 from './icon96-02.svg';
import HowToPpurchaseRublImg3 from './icon96-03.svg';
import HowToPpurchaseRublImg4 from './icon96-04.svg';

export default () => (
  <div className="how-to-purchase-rubl">
    <div className="how-to-purchase-rubl__header">
      <H1 text="How to purchase RUBL" />
      <div className="how-to-purchase-rubl__subtitle">Follow simple steps to purchase RUBL to your account</div>
    </div>

    <div className="how-to-purchase-rubl__list">
      <div className="how-to-purchase-rubl__item">
        <div className="icon-text-vertical">
          <img src={HowToPpurchaseRublImg1} alt="icon" />
          <div className="icon-text-vertical__body">
            1. Choose amount
            <br />
            to purchase
          </div>
        </div>
      </div>
      <div className="how-to-purchase-rubl__item">
        <div className="icon-text-vertical">
          <img src={HowToPpurchaseRublImg2} alt="icon" />
          <div className="icon-text-vertical__body">
            2. Request invoice
            <br />
            to your e-mail
          </div>
        </div>
      </div>
      <div className="how-to-purchase-rubl__item">
        <div className="icon-text-vertical">
          <img src={HowToPpurchaseRublImg3} alt="icon" />
          <div className="icon-text-vertical__body">
            3. Pay invoice via
            <br />
            your bank
          </div>
        </div>
      </div>
      <div className="how-to-purchase-rubl__item">
        <div className="icon-text-vertical">
          <img src={HowToPpurchaseRublImg4} alt="icon" />
          <div className="icon-text-vertical__body">
            4.Get RUBL to your
            <br />
            account
          </div>
          <div className="icon-text-vertical__bottom">the next business day</div>
        </div>
      </div>
    </div>
  </div>
);
