import React, { useState } from 'react';

import { FormItem } from '../form-item/form-item';
import InputWithPlaceholder from '../input-with-placeholder';
import { Btn } from '../btn/btn';
import './form.scss';
import { handleValidation, strongPasswordValidation } from '../../store/actions';

const LoginForm = ({
  authUser,
  history,
  authTwoFactor,
  twoFactorId,
}) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [authCode, setAuthCode] = useState('');

  const handleSubmit = e => {
    e && e.preventDefault();
    if (!login || !password) {
      return;
    }
    if (twoFactorId && !authCode) {
      return;
    }

    if (twoFactorId) {
      authTwoFactor(authCode, history);
      return;
    }

    authUser(login, password, history);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  function onChangeEmail(val) {
    const isValid = handleValidation('^([a-zA-Z0-9.-]+@+[a-zA-Z0-9]{2,15}\\.[a-z]{2,5})', val);
    isValid && setLogin(val);
  }

  function onChangePassword(val) {
    const isValid = strongPasswordValidation(val);
    isValid && setPassword(val);
    // setPassword(val);
  }

  function onChangeAuthCode(val) {
    const isValid = handleValidation('^(\\d{6}$)', val);
    if (isValid) {
      setAuthCode(val);
    } else {
      setAuthCode('');
    }
  }

  return (
    <form className="form" onSubmit={handleSubmit} autoComplete="off">
      <div className="form__block">
        <FormItem>
          <InputWithPlaceholder
            name="email"
            placeholder="Email"
            onChange={onChangeEmail}
            onKeyDown={handleKeyDown}
          />
        </FormItem>
      </div>

      <div className="form__block">
        <FormItem>
          <InputWithPlaceholder
            name="pass"
            placeholder="Password"
            type="password"
            onChange={onChangePassword}
            onKeyDown={handleKeyDown}
          />
        </FormItem>
      </div>

      {!!twoFactorId && (
        <div className="form__block">
          <FormItem>
            <InputWithPlaceholder
              name="google-auth"
              placeholder="Google Authentication Code"
              onChange={onChangeAuthCode}
              onKeyDown={handleKeyDown}
              autoFocus
              type="number"
            />
          </FormItem>
        </div>
      )}

      <div className="form__block">
        <Btn
          text="Log in"
          mod="btn--lg btn--block"
          type="submit"
          disabled={!login || !password || (twoFactorId && !authCode)}
        />
      </div>
    </form>
  );
};

export default LoginForm;
