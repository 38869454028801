import React from 'react';
import './powered-by-gp7.scss';
import PoweredByGp7Logo from './powered-by-gp7__logo.svg';

export const PoweredByGp7 = () => (
  <a className="powered-by-gp7" href="https://www.gp7.co" target="_blank" rel="noreferrer">
    <img src={PoweredByGp7Logo} alt="GP7" />
    <span>Powered by GP7</span>
  </a>
);
