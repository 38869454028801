import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Div from '../clickable-div';
import { Btn } from '../btn/btn';
import { FormItem } from '../form-item/form-item';
import InputWithPlaceholder from '../input-with-placeholder';
import { ReactComponent as CloseIcon } from './close-icon.svg';

import './modal.scss';
import { handleValidation } from '../../store/actions';

const Modal = ({
  submitCode,
  toggleVerifyWithdraw,
}) => {
  const [code, setCode] = useState('');

  const handleSubmit = e => {
    e && e.stopPropagation();
    e && e.preventDefault();
    submitCode(code);
  };

  const handleCode = value => {
    const isValid = handleValidation('^(\\d{6}$)', value);
    if (isValid) {
      setCode(value);
    } else {
      setCode('');
    }
  };

  const handleKeyDown = e => {
    e && e.stopPropagation();
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleCloseClick = e => {
    e && e.stopPropagation();
    toggleVerifyWithdraw();
  };

  return (
    <div className="modal-wrapper">
      <div className="modal">
        <Div className="modal__close" onClick={handleCloseClick}>
          <CloseIcon />
        </Div>

        <div className="modal__body">
          <h1 className="modal__title">Confirm withdraw</h1>

          <form className="modal__form" onSubmit={handleSubmit}>
            <FormItem>
              <InputWithPlaceholder
                name="google-auth"
                placeholder="Google Authentication Code"
                onChange={handleCode}
                onKeyDown={handleKeyDown}
                type="number"
                autoFocus
              />
            </FormItem>
            <div className="modal__form-button">
              <Btn
                text="Confirm"
                mod="btn--lg btn--block"
                type="submit"
                disabled={!code}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
