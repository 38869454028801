import {
  SET_APP_STATE,
  SET_SUPPORTED_CURRENCIES,
  SET_CURRENCY_RATE,
  SET_LOADING,
  CLEAR_DATA,
  SET_BACK_BUTTON_VISIBILITY,
  SET_PUSH_MESSAGE,
  SET_NOTICE,
  SHOW_WITHDRAW_CONFIRM_MODAL,
} from '../../constants/actions';

const initState = {
  isLoading: false,
  currencies: [],
  rate: {},
  isBackButtonVisible: false,
  pushMessage: '',
  noticeEmail: '',
  noticeType: '',
  shouldShowConfirmWithdrawModal: false,
  confirmWithdrawModalCb: null,
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_APP_STATE:
      return {
        ...state,
        ...payload,
      };
    case SET_SUPPORTED_CURRENCIES:
      return {
        ...state,
        currencies: [...payload],
      };
    case SET_CURRENCY_RATE:
      return {
        ...state,
        rate: { ...payload },
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_BACK_BUTTON_VISIBILITY:
      return {
        ...state,
        isBackButtonVisible: payload,
      };
    case SET_PUSH_MESSAGE:
      return {
        ...state,
        pushMessage: payload,
      };
    case SET_NOTICE:
      return {
        ...state,
        noticeType: payload.type,
        noticeEmail: payload.email,
      };
    case SHOW_WITHDRAW_CONFIRM_MODAL:
      return {
        ...state,
        shouldShowConfirmWithdrawModal: payload.shouldShow,
        confirmWithdrawModalCb: payload.cb,
      };
    case CLEAR_DATA:
      return {
        ...initState
      };
    default:
      return state;
  }
};
