import React from 'react';

import { Form } from '../../modules/forms';
import H1 from '../../modules/h1';
import { WITHDRAW_RUBL } from '../../constants';
import './withdraw.scss';

const Withdraw = ({
  title = WITHDRAW_RUBL,
}) => (
  <div className="withdraw">
    <div className="withdraw__title">
      <H1 text={title} />
    </div>

    <div className="withdraw__body">
      <Form.Withdraw />
    </div>
  </div>
);

export default Withdraw;
