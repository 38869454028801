import React from 'react';

import NoticeImgNotVerified from './notice__not-verified.svg';
import NoticeImgNoTransactions from './notice__no-transactions.svg';
import NoticeImgInvoiceRequested from './notice__invoice-requested.svg';
import NoticeImgEmailVerified from './notice__email-verified.svg';
import NoticeImgVerifyEmail from './notice__verify-email.svg';

import {
  VERIFY_EMAIL,
  VERIFIED_EMAIL,
  INVOICE_REQUESTED,
  REDEEM_REQUESTED,
  ACCOUNT_NOT_VERIFIED,
  INTRO_PATH,
  LOGIN_PATH,
} from '../../constants';

const noticeTypes = {
  [VERIFY_EMAIL]: {
    img: NoticeImgVerifyEmail,
    imgMod: 'notice__icon--sm',
    title: 'Verify email address',
    msgText: (email = '') => (
      <>
        {/* eslint-disable-next-line */}
        We sent to <b>{email}</b> verification link.
        <br />
        Please check your mail to verify your account.
      </>
    ),
    // btnText: 'Log In',
    // btnMod: 'btn--lg',
    // buttonClickPath: LOGIN_PATH,
  },
  [VERIFIED_EMAIL]: {
    img: NoticeImgEmailVerified,
    imgMod: 'notice__icon--sm',
    title: 'Email verified',
    msgText: (
      <>
        You’ve successfully verify your email address.
        <br />
        Now you can log in and get access to servece.
      </>
    ),
    btnText: 'Log In',
    btnMod: 'btn--lg',
    buttonClickPath: LOGIN_PATH,
  },
  [INVOICE_REQUESTED]: {
    img: NoticeImgInvoiceRequested,
    imgMod: 'notice__icon--sm',
    title: 'Invoice requested',
    msgText: (
      <>
        You’ve successfully requested an invoice.
        <br />
        Check your mail to pay one.
      </>
    ),
    btnText: 'Return to main page',
    btnMod: 'btn--lg',
    buttonClickPath: INTRO_PATH,
  },
  [REDEEM_REQUESTED]: {
    img: NoticeImgInvoiceRequested,
    imgMod: 'notice__icon--sm',
    title: 'Redeem requested',
    msgText: (
      <>
        Wait up to 24 hours until we validate
        <br />
        your request for RUBL sale
      </>
    ),
    btnText: 'Return to main page',
    btnMod: 'btn--lg',
    buttonClickPath: INTRO_PATH,
  },
  [ACCOUNT_NOT_VERIFIED]: {
    img: NoticeImgNotVerified,
    msgText: 'Your account is not verified',
    msgTextMod: 'notice__msg--brown',
    btnText: 'Verify account',
  }
};

export default noticeTypes;
