import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import app from './app';
import account from './account';
import auth from './auth';

const appReducer = combineReducers({
  form,
  account,
  auth,
  app,
});

export default (state, action) => appReducer(state, action);
