import React from 'react';
import classNames from 'classnames';

// import { OperationsItem } from '../operations-list-month/operations-list-month';
import './operations-list-month.scss';
import { ReactComponent as AcquireIcon } from './operations-list-month__acquire-icon.svg';
import { ReactComponent as WithdrawIcon } from './operations-list-month__withdraw-icon.svg';
import { ReactComponent as RedeemIcon } from './operations-list-month__redeem-icon.svg';
import { ReactComponent as DepositIcon } from './operations-list-month__deposit-icon.svg';
import {
  ACQUIRE,
  WITHDRAW,
  REDEEM,
  DEPOSIT,
  PENDING,
} from '../../../constants';

const OperationsListMonth = ({
  month,
  monthlyTransactions,
  onClick,
}) => {
  const handleOnClick = (e, item) => {
    e && e.stopPropagation();
    onClick(item);
  };

  const handleOnKeyDown = (e, item) => {
    if (e.keyCode === 13) {
      handleOnClick(e, item);
    }
  };

  return (
    <div className="operations-list-month">
      <div className="operations-list-month__title">{month}</div>

      <div className="operations-list-month__list">
        {monthlyTransactions.map(transaction => {
          const {
            createdAt,
            value,
            id,
            type,
            status
          } = transaction;

          let Icon;
          switch (type) {
            case ACQUIRE:
              Icon = AcquireIcon;
              break;
            case WITHDRAW:
              Icon = WithdrawIcon;
              break;
            case DEPOSIT:
              Icon = DepositIcon;
              break;
            case REDEEM:
            default:
              Icon = RedeemIcon;
              break;
          }

          const newDate = new Date(createdAt);
          const date = newDate.getDate();
          let hours = newDate.getHours();
          let minutes = newDate.getMinutes();
          if (hours.toString().length === 1) {
            hours = `0${hours}`;
          }
          if (minutes.toString().length === 1) {
            minutes = `0${minutes}`;
          }

          const dateString = `${date} ${month}, ${hours}:${minutes}`;

          return (
            <div className="operations-list-month__list__wrapper">
              <div
                key={id}
                className="operations-list-month__list__item"
                onClick={e => handleOnClick(e, transaction)}
                onKeyDown={e => handleOnKeyDown(e, transaction)}
                role="button"
                tabIndex={0}
              >
                <div className="operations-list-month__list__item--left">
                  <Icon className="operations-list-month__list__item--icon" />

                  <div className="operations-list-month__list__item--date">{dateString}</div>
                  {status === PENDING && (
                    <div className="operations-list-month__list__item--status">Pending</div>
                  )}
                </div>

                <div className="operations-list-month__list__item--right">{`${value} RUBL`}</div>
              </div>
              <div className="operations-list-month__list__divider" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OperationsListMonth;
