import React from 'react';
import { Btn } from '../btn/btn';
import { FormItem } from '../form-item/form-item';
import InputWithPlaceholder from '../input-with-placeholder';
import './form.scss';

export default ({
  text,
}) => (
  <form className="form">
    <div className="form__block">
      <FormItem
        item={(
          <InputWithPlaceholder
            name="eamil"
            placeholder="Email address"
          />
        )}
      />
    </div>

    <div className="form__block">
      <Btn
        text="Reset"
        mod="btn--lg btn--block"
      />
    </div>
  </form>
);
