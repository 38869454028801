import React, { useRef } from 'react';
import classNames from 'classnames';
import './crypto-address.scss';
import CryptoAddressImg from './crypto-address__icon.svg';

const CryptoAddress = ({
  address
}) => {
  const addressRef = useRef(null);

  const handleCopy = e => {
    e.stopPropagation();
    addressRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div className="crypto-address">
      <input ref={addressRef} value={address} readOnly />
      <button
        type="button"
        className="crypto-address__btn"
        onClick={handleCopy}
      >
        <img src={CryptoAddressImg} alt="" />
      </button>
    </div>
  );
};

export default CryptoAddress;
