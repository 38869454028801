import axios from 'axios';
import { get } from 'lodash';
import QRCode from 'qrcode';
import { Dispatch } from 'react';
import {
  ACCOUNTS_URL,
  PROFILE_URL,
  BALANCE_URL,
  FEES_URL,
  WITHDRAW_URL,
  CONFIRM_WITHDRAW_URL
} from '../../api/urls';
import {
  SHOW_WITHDRAW_CONFIRM_MODAL,
  UDPATE_USER_ACCOUNT,
  UPDATE_OPERATIONS,
  SET_FEES,
  STATUS_200,
} from '../../constants';
import { setAppLoading } from './app';

interface IProfileData {
  login: string;
  email: string;
  displayName: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  verified: boolean;
  kycVerificationStatus: string;
  status: string;
  role: string;
}

interface IProfileResponse {
  status: number;
  data: IProfileData[];
}

interface ITransaction {
  id: number;
  accountId: number;
  type: string;
  status: string;
  value: number;
  fee: number;
  feeType: string;
  recipient: string;
  confirmedAt: string;
  nonce: number;
  transactionHash: string;
  createdAt: string;
}

interface IWithdrawData {
  feeType: string;
  rublValue: string;
  address: string;
}

export const fetchAccountInfo = () => async (dispatch: Dispatch<any>) => {
  try {
    const accountResponse = await axios.get(ACCOUNTS_URL);
    const profileResponse: IProfileResponse = await axios.get(PROFILE_URL);

    if (accountResponse.status === STATUS_200) {
      const { address, balance, id } = accountResponse.data[0];

      const addressQr = await QRCode.toDataURL(address, {
        color: {
          light: '##f1f1f1',
        },
        margin: 2,
        scale: 6
      });

      dispatch({
        type: UDPATE_USER_ACCOUNT,
        payload: {
          address,
          addressQr,
          balance,
          id,
        },
      });
    }

    if (profileResponse.status === STATUS_200) {
      dispatch({
        type: UDPATE_USER_ACCOUNT,
        payload: {
          ...profileResponse.data
        },
      });
    }
  } catch (e) {
    console.error('error');
  }
};

export const fetchOperations = () => async (dispatch: Dispatch<any>) => {
  const response = await axios.get(BALANCE_URL);

  if (response.status === STATUS_200) {
    dispatch({
      type: UPDATE_OPERATIONS,
      payload: [...response.data],
    });
  }
};

export const fetchFees = () => async (dispatch: Dispatch<any>) => {
  const response = await axios.get(FEES_URL);

  if (response.status === STATUS_200) {
    dispatch({
      type: SET_FEES,
      payload: { ...response.data }
    });
  }
};

export const toggleVerifyWithdraw = (cb?: () => void) => (dispatch: Dispatch<any>) => {
  let shouldShow = false;
  if (cb) {
    shouldShow = true;
  }

  dispatch({
    type: SHOW_WITHDRAW_CONFIRM_MODAL,
    payload: {
      shouldShow,
      cb,
    },
  });
};

export const submitCode = (code: string) => async (dispatch: Dispatch<any>, getState: () => Record<string, any>) => {
  const withdrawCb = getState().app.confirmWithdrawModalCb;
  const payload = {
    id: 'confirm code',
    code,
  };

  const response = await axios.post(CONFIRM_WITHDRAW_URL, payload);

  dispatch(toggleVerifyWithdraw());

  const status = get(response, 'status', 0);
  if (status === STATUS_200) {
    withdrawCb && withdrawCb();
  }
};

export const withdraw = (data: IWithdrawData, cb: () => void) => async (
  dispatch: Dispatch<any>, getState: () => Record<string, any>
) => {
  dispatch(setAppLoading(true));

  const accountId = getState().account.id;
  const payload = {
    ...data,
    accountId,
  };

  try {
    const response = await axios.post(WITHDRAW_URL, payload);
    if (response.status === STATUS_200) {
      dispatch(setAppLoading(false));
      cb && cb();
    }
  } catch (e) {
    console.error(e);
  }
};
