import React from 'react';
import './qr-token.scss';

const QrToken = ({
  qrCode,
  qrDescription,
}) => (
  <div className="qr-token">
    <h2 className="qr-token__title">Scan the QR Code or enter the token</h2>
    <img className="qr-token__img" src={qrCode} alt="QR code" />
    <div className="qr-token__key">
      Account token (Key):
      <b>{qrDescription}</b>
    </div>
    <div className="qr-token__desc">
      {
        `The token will not be shown again after 2FA is enabled.
        If you have multiple devices, add your account token to all of them before clicking enable.
        (Note: Your Account Token will change each time you reload your browser.)`
      }
    </div>
  </div>
);

export default QrToken;
