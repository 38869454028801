import React, { Component } from 'react';

import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';
import { FormItem } from '../form-item/form-item';
import InputWithPlaceholder from '../input-with-placeholder';
import {
  PASSWORDS_DONT_MATCH,
  PASSWORDS_MATCH,
  CAPTCHA_KEY,
  PASSWORDS_VALIDATION
} from '../../constants';
import { Btn } from '../btn/btn';
import './form.scss';
import { CheckboxText } from '../checkbox-text';
import { handleValidation, strongPasswordValidation } from '../../store/actions';

const formFields = [
  {
    name: 'firstName',
    placeholder: 'First name',
  },
  {
    name: 'lastName',
    placeholder: 'Last name',
  },
  {
    name: 'email',
    placeholder: 'Email',
  },
  {
    name: 'password',
    placeholder: 'Password',
  },
  {
    name: 'confirmPassword',
    placeholder: 'Confirm password',
  },
];

export default class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      captcha: '',
      matchPassword: '',
      checkbox: ''
    };

    loadReCaptcha();
  }

  updateField = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (value, name) => {
    const { confirmPassword, password } = this.state;
    switch (name) {
      case 'email':
        handleValidation('^([a-zA-Z0-9.-]+@+[a-zA-Z0-9]{2,15}\\.[a-z]{2,5})', value) &&
        this.updateField(name, value);
        break;
      case 'password':
        strongPasswordValidation(value) &&
        this.updateField(name, value);
        if (confirmPassword && confirmPassword !== value) {
          this.updateField('matchPassword', '');
        }
        if (confirmPassword && confirmPassword === value) {
          this.updateField('matchPassword', true);
        }
        if (value.length > 0 && value.length < 8) {
          this.setState({ errorMessage: PASSWORDS_VALIDATION });
        } else {
          this.setState({ errorMessage: '' });
        }
        break;
      case 'checkbox':
        this.updateField(name, value);
        break;
      case 'confirmPassword':
        this.updateField(name, value);
        if (value === password) {
          this.updateField('matchPassword', true);
          this.setState({ errorMessage: '' });
        } else {
          this.updateField('matchPassword', '');
          this.setState({ errorMessage: PASSWORDS_DONT_MATCH });
        }
        break;
      default:
        this.setState({
          [name]: value,
        });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { signupUser, history } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      checkbox
    } = this.state;

    if (password !== confirmPassword) {
      this.setState({ errorMessage: PASSWORDS_DONT_MATCH });
      return;
    }

    if (checkbox === 'off') {
      // TODO: add error handler
      return;
    }

    signupUser({
      firstName,
      lastName,
      email,
      password,
    }, history);
  };

  verifyRecaptcha = recaptchaToken => {
    this.updateField('captcha', true);
    // console.log(recaptchaToken, '<= your recaptcha token');
  };

  render() {
    const {
      errorMessage,
      firstName,
      lastName,
      password,
      confirmPassword,
      matchPassword,
      checkbox,
      captcha
    } = this.state;

    const isButtonDisabled = !firstName || !lastName || !password || !matchPassword || !checkbox || !captcha;

    return (
      <div className="submit-form">
        <form id="submit-form" onSubmit={this.handleSubmit} autoComplete="off">
          {formFields.map(({ name, placeholder }) => (
            <div className="submit-form__block" key={`signUp${name}`}>
              <FormItem
                item={(
                  <InputWithPlaceholder
                    name={name}
                    placeholder={placeholder}
                    onChange={value => this.handleChange(value, name)}
                    type={(name === 'password' || name === 'confirmPassword') ? 'password' : ''}
                  />
                )}
                bottomError={(name === 'password' && errorMessage === PASSWORDS_VALIDATION) ||
                (name === 'confirmPassword' && errorMessage === PASSWORDS_DONT_MATCH) ? errorMessage : ''}
                bottomVerified={name === 'confirmPassword' && matchPassword && password && confirmPassword ?
                  PASSWORDS_MATCH : ''}
              />
            </div>
          ))}

          <div className="submit-form__block">
            <FormItem
              item={(
                <CheckboxText
                  text={(
                    <>
                      By checking this box I confirm I wish to create an account
                      with RUBL and that I have read the
                      {' '}
                      <a
                        href="https://docs.gp7.app/legal/terms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        RUBL terms of service
                      </a>
                      {' '}
                      and
                      {' '}
                      <a
                        href="https://docs.gp7.app/legal/privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        privacy policy
                      </a>
                      {' '}
                      and agree to them.
                    </>
                  )}
                  onChange={e => this.handleChange(e.target.checked, 'checkbox')}
                />
              )}
            />
          </div>
        </form>

        <div className="submit-form__block">
          <ReCaptcha
            size="normal"
            render="explicit"
            sitekey={CAPTCHA_KEY}
            verifyCallback={this.verifyRecaptcha}
          />
        </div>

        <div className="submit-form__block">
          <Btn
            text="Create account"
            mod="btn--lg btn--block"
            type="submit"
            form="submit-form"
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    );
  }
}
