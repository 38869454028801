import React, { useState, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';

import { InputDesc } from '../input/input-desc';
import Select from '../select';
import './input-select.scss';

export const InputSelect = ({
  items,
  value,
  onChange,
  onSelectionChange,
}) => {
  const [selectedValue, setSelection] = useState({});
  const { name, label } = selectedValue;

  useEffect(() => {
    if (isEmpty(selectedValue) && items && items.length > 0) {
      const rubItem = items.find(item => item.code === 'RUB');
      const itemToSelect = rubItem ? rubItem : items[0];
      setSelection(itemToSelect);
      onSelectionChange(itemToSelect);
    }
  });

  const handleInputChange = inputValue => {
    onChange(inputValue, selectedValue);
  };

  const updateSelection = item => {
    if (!isEqual(item, selectedValue)) {
      setSelection(item);
      onSelectionChange && onSelectionChange(item);
    }
  };

  return (
    <div className="input-select">
      <InputDesc
        name={name}
        placeholder={0}
        desc={label}
        onChange={handleInputChange}
        value={value}
        type="amount"
      />

      {items && (
        <Select
          items={items}
          selectedItem={selectedValue}
          onSelect={updateSelection}
        />
      )}
    </div>
  );
};
