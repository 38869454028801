/* eslint-disable */
import React, { useEffect } from 'react';
import { Btn } from '../btn/btn';
import './notice.scss';

export const Notice = ({
  img,
  imgMod,
  title,
  btnText,
  msgText,
  msgTextMod,
  btnMod,
  history,
  buttonClickPath,
  clearNotice,
  checkVerifiedEmail,
}) => {
  const handleClick = e => {
    e && e.stopPropagation();
    if (!buttonClickPath) return;
    clearNotice();
    history.push(buttonClickPath);
  };

  const parseQueryString = () => {
    const queryString = window.location.search;
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  useEffect(() => {
    const token = parseQueryString().token;
    if (parseQueryString().token) {
      checkVerifiedEmail(token, history);
    }
    return clearNotice;
  }, []);

  return (
    <div className="notice">
      <div className={`notice__icon ${imgMod || ''}`}>
        <img src={img} alt="" />
      </div>

      {title && (
        <h1 className="h1 notice__title">{title}</h1>
      )}

      {msgText && (
        <div className={`notice__msg ${msgTextMod || ''}`}>{msgText}</div>
      )}

      {btnText && (
        <div className="notice__action">
          <Btn
            text={btnText}
            mod={btnMod}
            onClick={handleClick}
          />
        </div>
      )}
    </div>
  )
};
