import React from 'react';
import './checkbox.scss';

export const Checkbox = ({
  text,
  name,
  onChange,
}) => (
  <div className="checkbox">
    <label htmlFor={name}>
      <input
        type="checkbox"
        name={name}
        id={name}
        onChange={onChange}
      />
      <span />
    </label>
  </div>
);
