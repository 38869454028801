import React from 'react';
import Authorization from '../../modules/authorization';
import { Form } from '../../modules/forms';

export default () => (
  <Authorization
    type="signup"
    menu
    body={<Form.SignUp />}
  />
);
