export const RUBL_ACCOUNT_BALANCE = 'RUBL ACCOUNT BALANCE';
export const UNKNOWN_USER = 'UNKNOWN USER';
export const PASSWORDS_DONT_MATCH = 'Passwords don\'t match';
export const PASSWORDS_VALIDATION = 'Password must be at least 8 characters long';
export const PASSWORDS_MATCH = 'Passwords match';
export const ACQUIRE = 'ACQUIRE';
export const WITHDRAW = 'WITHDRAW';
export const REDEEM = 'REDEEM';
export const DEPOSIT = 'DEPOSIT';
export const PENDING = 'PENDING';
export const USD = 'USD';
export const WITHDRAW_RUBL = 'Withdraw RUBL';
export const REDEEM_RUBL = 'Redeem RUBL';

// PATHS
export const LOGIN_PATH = '/login';
export const TWO_FACTOR_PATH = '/two-factor';
export const INTRO_PATH = '/intro';
export const SIGNUP_PATH = '/signup';
export const ACQUIRE_PATH = '/acquire';
export const BALANCE_PATH = '/balance';
export const WITHDRAW_PATH = '/withdraw';
export const DEPOSIT_PATH = '/deposit';
export const REDEEM_PATH = '/redeem';
export const PROFILE_PATH = '/profile';
export const VERIFY_PATH = '/verify';
export const NOTICE_PATH = '/notice';
export const TRANSACTION_INFO_LINK = 'https://etherscan.io/tx/';

// API STATUSES
export const STATUS_200 = 200;
export const STATUS_202 = 202;
export const UNAUTHORIZED_401 = 401;
export const ILLEGAL_ARGUMENTS_400 = 400;

export const VERIFY_AUTH_TIMEOUT = 1000 * 60; // 1 minute
export const PUSH_TIMEOUT = 1000 * 5;
export const FETCH_DATA_TIMEOUT = 1000 * 7;

// PUBLIC
export const CAPTCHA_KEY = '6Ld-afcZAAAAALBfWVR9kQvcUDDhxmpLogCCdtT9';

// ERROR MESSAGES
export const AUTH_ERROR = 'Incorrect email or password!';
export const TOKEN_EXPIRED = 'Session expired. Please, Log In again';
export const ILLEGAL_ARGUMENTS_ERROR = 'Illegal arguments';
export const STANDART_ERROR = 'Something went wrong, please try again';
export const VERIFICATION_CODE_ERROR = 'Verification error!';

// ERROR CODES
export const VERIFICATION_CODE = 'VERIFICATION_CODE';

// NOTICE TYPES
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFIED_EMAIL = 'VERIFIED_EMAIL';
export const INVOICE_REQUESTED = 'INVOICE_REQUESTED';
export const REDEEM_REQUESTED = 'REDEEM_REQUESTED';
export const ACCOUNT_NOT_VERIFIED = 'ACCOUNT_NOT_VERIFIED';
