import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';

import BalanceHeader from './balance-header';

export default connect(
  state => ({
    balance: state.account.balance,
    rate: get(state, 'app.rate.rate', 1),
  })
)(withRouter(BalanceHeader));
