import axios from 'axios';
import { get } from 'lodash';

import { ACQUIRE_URL, SUPPORTED_CURRENCIES, CURRENCY_RATE } from '../../api/urls';
import {
  STATUS_200,
  CURRENCY_MAP,
  SET_SUPPORTED_CURRENCIES,
  SET_CURRENCY_RATE,
  NOTICE_PATH,
  INVOICE_REQUESTED,
} from '../../constants';
import { setNoticeType, setAppLoading } from './app';

export const fetchRates = currency => async dispatch => {
  const response = await axios.get(CURRENCY_RATE, {
    params: {
      currency,
    }
  });

  if (response.status === STATUS_200) {
    const rates = get(response, 'data', {});
    dispatch({
      type: SET_CURRENCY_RATE,
      payload: { ...rates },
    });
  }
};

export const acquire = (rublValue, currency, history) => async (dispatch, getState) => {
  dispatch(setAppLoading(true));
  const accountId = get(getState(), 'account.id', '');
  if (!accountId) return;

  const payload = {
    accountId,
    currency,
    rublValue: +rublValue,
  };

  const { status, data } = await axios.post(ACQUIRE_URL, payload);
  if (status === STATUS_200) {
    dispatch(setNoticeType(INVOICE_REQUESTED));
    dispatch(setAppLoading(false));
    history.push(NOTICE_PATH);
  }
};

export const fetchCurrencies = () => async dispatch => {
  const currencyResponse = await axios.get(SUPPORTED_CURRENCIES);

  if (currencyResponse.status === STATUS_200) {
    const currencies = get(currencyResponse, 'data', []);
    const formattedCurrencies = currencies.map(currency => ({
      name: CURRENCY_MAP[currency] ? CURRENCY_MAP[currency].name : currency,
      label: CURRENCY_MAP[currency] ? CURRENCY_MAP[currency].label : currency[0],
      code: currency,
    }));

    dispatch({
      type: SET_SUPPORTED_CURRENCIES,
      payload: [...formattedCurrencies],
    });
    dispatch(fetchRates('RUB'));
    // dispatch(fetchRates(currencies[0]));
  }
};
