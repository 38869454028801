import { connect } from 'react-redux';

import { setPushMessage } from '../../store/actions';
import Push from './push';

export default connect(
  state => ({
    message: state.app.pushMessage
  }),
  {
    setPushMessage
  }
)(Push);
