import React from 'react';
import { DEPOSIT_PATH, WITHDRAW_PATH } from '../../constants';
import { Btn } from '../btn/btn';
import H1 from '../h1';
import './balance-header.scss';
import BalanceHeaderImg from './logo--sm.svg';

export default ({
  balance,
  rate,
  history,
}) => (
  <div className="balance-header">
    <div className="balance-header__top">
      <H1 text="RUBL balance" className="balance-header__top_title" />
    </div>

    <div className="balance-header__body">
      <div className="balance-header__left">
        <img className="balance-header__logo" src={BalanceHeaderImg} alt="logo" />
        <div className="balance-header__sum">{balance}</div>
        <div className="balance-header__sum-sm">{rate && !!balance && `~ ${(balance / rate).toFixed(2)} $`}</div>
      </div>

      <div className="balance-header__right">
        <Btn
          text="Withdraw"
          onClick={() => history.push(WITHDRAW_PATH)}
        />

        <Btn
          text="Deposit"
          mod="btn--black"
          onClick={() => history.push(DEPOSIT_PATH)}
        />
      </div>
    </div>
  </div>
);
