import React from 'react';

import { LOGIN_PATH } from '../../constants';
import Div from '../clickable-div';
import './user-menu.scss';
import UserMenuUserPic from './user-menu__user-pic.svg';

const UserMenu = ({
  name,
  history,
  deleteAuthorization,
}) => (
  <div className="user-menu">
    <div className="user-menu__header">
      <div className="user-menu__user-pic">
        <img src={UserMenuUserPic} alt="user pic" />
      </div>

      <div className="user-menu__name">{name}</div>
    </div>
    <div className="user-menu__menu">
      <li className="user-menu__item">
        <Div className="user-menu__link" onClick={() => history.push('/profile')}>
          Profile
        </Div>
      </li>
      <li className="user-menu__item">
        <Div className="user-menu__link" onClick={() => history.push('/verification')}>
          Verification
        </Div>

      </li>
      <li className="user-menu__item">
        <Div className="user-menu__link" onClick={() => deleteAuthorization(() => history.push(LOGIN_PATH))}>
          Log out
        </Div>
      </li>
    </div>
  </div>
);

export default UserMenu;
