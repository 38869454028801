import {
  AUTHORIZE,
  DELETE_AUTH,
  SET_TWO_FACTOR_PARAMS,
  SET_QR_CODE_DATA,
  CLEAR_DATA,
} from '../../constants/actions';

const initState = {
  isAuthorized: false,
  jwt: '',
  expirationDate: 0,
  needTwoFactor: false,
  qrInfo: {},
  twoFactorId: '',
  secondsTwoFactorFormValid: 0,
  twoFactorInfo: {},
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case AUTHORIZE:
    case SET_TWO_FACTOR_PARAMS:
      return {
        ...state,
        ...payload,
      };
    case SET_QR_CODE_DATA:
      return {
        ...state,
        qrInfo: {
          ...payload,
        },
      };
    case CLEAR_DATA:
      return {
        ...initState
      };
    default:
      return state;
  }
};
