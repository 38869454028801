import React from 'react';
import { isNil } from 'lodash';

import './account-balance.scss';

const AccountBalance = ({
  balance,
  desc,
}) => (
  <div className="account-balance">
    {!isNil(balance) && (
      <>
        <div className="account-balance__sum">{balance}</div>
        <div className="account-balance__desc">{desc}</div>
      </>
    )}
  </div>
);

export default AccountBalance;
