import React from 'react';
import { InputDesc } from '../input/input-desc';
import './input-text.scss';

export const InputText = ({
  placeholder = '',
  name = '',
  description = '',
  additionalInfo,
  onChange,
  className,
  type = 'text'
}) => (
  <div className="input-text">
    <InputDesc
      name={name}
      placeholder={placeholder}
      desc={description}
      onChange={onChange}
      className={className}
      type={type}
      // limit={type === 'amount' && name === 'withdraw amount' ? additionalInfo : ''}
    />

    <div className="input-text__text">{additionalInfo}</div>
  </div>
);
