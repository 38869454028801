import React, { useEffect } from 'react';

import BalanceHeader from '../../modules/balance-header';
import OperationsList from '../../modules/operations-list';
import { Notice } from '../../modules/notice/notice';
import NoticeImgNoTransactions from '../../modules/notice/notice__no-transactions.svg';
import { USD, FETCH_DATA_TIMEOUT } from '../../constants';

import './balance.scss';

export default ({
  operations,
  fetchOperations,
  fetchRates,
}) => {
  let timer = null;

  const fetchData = () => {
    fetchOperations();
    fetchRates(USD);
  };

  useEffect(() => {
    fetchData();

    if (!timer) {
      timer = setInterval(fetchData, FETCH_DATA_TIMEOUT);
    }

    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, []);

  return (
    <div className="balance">
      <BalanceHeader />

      <div className="balance__body">
        {operations.length > 0 ? (
          <OperationsList />
        ) : (
          <Notice
            img={NoticeImgNoTransactions}
            title="You don’t have any transactions"
          />
        )}
      </div>
    </div>
  );
};
