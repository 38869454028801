import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { INTRO_PATH, LOGIN_PATH, SIGNUP_PATH } from '../../constants';
import './authorization.scss';

const authMenu = [
  {
    href: LOGIN_PATH,
    title: 'Log in',
  },
  {
    href: SIGNUP_PATH,
    title: 'Create account',
  }
];

const AuthorizationTitle = ({
  text,
}) => (
  <h1 className="h1">
    {text}
  </h1>
);

const AuthorizationSubTitle = ({
  text,
}) => (
  <p className="authorization__subtitle">
    {text}
  </p>
);

const AuthorizationMenu = ({ history }) => {
  const handleSwitch = href => {
    if (window.location.pathname !== href) {
      history.push(href);
    }
  };

  const onKeyDown = (e, href) => {
    if (e.keyCode === 13) {
      handleSwitch(href);
    }
  };

  return (
    <div className="authorization__menu">
      {
        authMenu.map(({ href, title }) => (
          <div
            className={classNames('authorization__switch-link', {
              'authorization__switch-link--active': window.location.pathname === href
            })}
            onClick={() => history.push(href)}
            onKeyDown={e => onKeyDown(e, href)}
            role="button"
            tabIndex={0}
            key={href + title}
          >
            {title}
          </div>
        ))
      }
    </div>
  );
};

const Authorization = ({
  type,
  title,
  subtitle,
  menu,
  body,
  history,
  isAuthorized,
}) => {
  useEffect(() => {
    if (isAuthorized) {
      history.push(INTRO_PATH);
    }
  });

  return (
    <div className="authorization">
      <div className="authorization__header">
        {title && <AuthorizationTitle text={title} />}

        {subtitle && <AuthorizationSubTitle text={subtitle} />}

        {menu && <AuthorizationMenu history={history} /> }
      </div>

      <div className="authorization__body">
        {body}
      </div>

      <div className="authorization__bottom">
        {type === 'login' ? (
          <div>
            Don’t have an account? Click to
            <a href="signup"> Sign Up</a>
          </div>
        ) : (
          <div>
            Do you have an account? Click to
            <a href="login"> Log In</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  state => ({
    isAuthorized: state.auth.isAuthorized,
  })
)(withRouter(Authorization));
