import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './spinner.scss';

const Spinner = ({ className, isLoading }) => (
  <div
    className={classNames('spinner', {
      'spinner--visible': isLoading,
    })}
  >
    <div
      className={classNames('loadingSpinner', {
        [className]: !!className,
      })}
    />
  </div>
);

export default connect(
  state => ({
    isLoading: state.app.isLoading,
  })
)(Spinner);
