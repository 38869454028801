import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Btn } from '../../modules/btn/btn';
import H1 from '../../modules/h1';
import './transaction-details.scss';
import {
  ACQUIRE,
  BALANCE_PATH, DEPOSIT,
  MONTH_MAP,
  TRANSACTION_INFO_LINK,
  USD,
} from '../../constants';

const TransactionDetails = ({
  allTransactions,
  history,
}) => {
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    if (allTransactions.length === 0) {
      history.push(BALANCE_PATH);
      return;
    }
    /* eslint-disable-next-line */
    const pathname = window.location.pathname;
    const numId = +pathname.split('/')[2];
    const openTransaction = allTransactions.find(transactionElem => (transactionElem.id === numId));
    console.log('openTransaction', openTransaction);
    if (!openTransaction) {
      history.push(BALANCE_PATH);
      return;
    }

    if (numId === openTransaction.id && isEmpty(transaction)) {
      setTransaction({ ...openTransaction });
    }
  });

  const getDate = initDate => {
    if (!initDate) return '';

    const newDate = new Date(initDate);
    const day = newDate.getDate();
    const month = MONTH_MAP[newDate.getMonth()];
    const year = newDate.getFullYear();
    const initHours = newDate.getHours().toString();
    const initMinutes = newDate.getMinutes().toString();

    const hours = initHours.length === 1 ? `0${initHours}` : initHours;
    const minutes = initMinutes.length === 1 ? `0${initMinutes}` : initMinutes;

    const date = `${day} ${month} ${year} ${hours}:${minutes}`;
    return date;
  };

  const texts = {
    ACQUIRE: 'acquired',
    WITHDRAW: 'withdrawn',
    REDEEM: 'redeemed',
    DEPOSIT: 'deposited',
  };

  const {
    value,
    recipient,
    transactionHash,
    fee,
    confirmations,
    createdAt,
    transactionId,
    type,
  } = transaction;
  const formattedFee = fee === undefined || fee === null ? 0 : fee;
  const formattedConfirmations = confirmations === undefined || confirmations === null ? 0 : confirmations;
  const transactionLink = transactionHash ? TRANSACTION_INFO_LINK + transactionHash : '';
  return (
    <div className="transaction-details">
      <div className="transaction-details__header">
        <H1 text="Transaction details" />
      </div>

      <div className="transaction-details__body">
        <h2 className="transaction-details__subtitle">
          {`You’ve successfully ${texts[type]}`}
        </h2>

        <div className="transaction-details__sum">
          <div>{value && value.toFixed(2)}</div>
          {/* format value to decimal points */}
          <div>RUBL</div>
        </div>
        {transactionLink && (
          <div>
            <a href={transactionLink} target="_blank" rel="noopener noreferrer">
              <div className="transaction-details__id">Transaction ID</div>
            </a>
          </div>
        )}

        <Btn
          text="Return to main page"
          mod="btn--lg"
          onClick={() => history.push(BALANCE_PATH)}
        />
      </div>

      <div className="transaction-details__bottom">
        <div className="transaction-details__item">
          {type !== DEPOSIT && (
            <div>Sent to</div>
          )}
          {type === DEPOSIT && (
            <div>Sent from</div>
          )}
          <div>{recipient}</div>
        </div>
        {type !== ACQUIRE && (
          <>
            <div className="transaction-details__item">
              <div>Fee</div>
              <div>{`${formattedFee} ETH`}</div>
            </div>
            <div className="transaction-details__item">
              <div>Confirmations</div>
              <div>{formattedConfirmations}</div>
            </div>
          </>
        )}
        <div className="transaction-details__item">
          <div>Date / time</div>
          <div>{getDate(createdAt)}</div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
