import React, { Component } from 'react';
import { Form } from '../../modules/forms';
import H1 from '../../modules/h1';
import { USD } from '../../constants';
import './redeem-form.scss';

export default class RedeemForm extends Component {
  componentDidMount() {
    const { fetchCurrencies, fetchRates } = this.props;
    fetchCurrencies();
    // fetchRates(USD);
  }

  render() {
    // const { title } = this.props;
    // TODO need title props
    const title = 'Redeem RUBL';
    return (
      <div className="redeem-form">
        <div className="redeem-form__title">
          <H1 text={title} />
        </div>

        <div className="redeem-form__body">
          <Form.Redeem />
        </div>
      </div>
    );
  }
}
