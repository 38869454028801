import React from 'react';
import classNames from 'classnames';

import { Prompt } from '../prompt/prompt';
import './form-item.scss';

export const FormItem = ({
  // doubleTitle,
  title,
  secondTitle,
  item,
  children,
  bottom,
  bottomVerified,
  bottomError,
  prompt,
  mod,
}) => (
  <div className={`form-item ${mod || ''}`}>
    {prompt && (
      <Prompt />
    )}

    {/* {doubleTitle && secondTitle && (
      <div className="form-item__double-title">
        <div>Amount</div>
        <div>Balance</div>
      </div>
    )} */}

    {title && (
      <div
        className={classNames({
          'form-item__title': secondTitle,
        })}
      >
        <div className="form-item__title-value">{title}</div>
        {secondTitle && (
          <div className="form-item__title-value--second">{secondTitle}</div>
        )}
      </div>
    )}

    {item || children}

    {bottom && (
      <div className="form-item__bottom">{bottom}</div>
    )}

    {bottomError && (
      <div className="form-item__bottom_error">{bottomError}</div>
    )}

    {bottomVerified && (
      <div className="form-item__bottom_verified">{bottomVerified}</div>
    )}
  </div>
);
