import React from 'react';
import './account-header.scss';
// import { ReactComponent as AccountHeaderUserPic } from './account-header__user-pic.svg'
import AccountHeaderUserPic from './account-header__user-pic.svg';
import UserMenu from '../user-menu';

const AccountHeader = ({
  accountName,
}) => (
  <div className="account-header">
    <div className="account-header__user-pic">
      {/* <AccountHeaderUserPic /> */}
      <img src={AccountHeaderUserPic} alt="User Pic" />
    </div>

    <div className="account-header__name">{accountName}</div>

    <div className="account-header__menu">
      <UserMenu accountName={accountName} />
    </div>
  </div>
);

export default AccountHeader;
