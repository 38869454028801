import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkVerifiedEmail, setNoticeType } from '../../store/actions';
import { VERIFY_EMAIL } from '../../constants';
import { Notice } from './notice';
import noticeTypes from './notice-types';

export default connect(
  state => {
    const { noticeType, noticeEmail } = state.app;
    let noticeProps = {};
    if (noticeTypes[noticeType]) {
      noticeProps = { ...noticeTypes[noticeType] };
    }

    if (noticeProps.msgText && noticeType === VERIFY_EMAIL) {
      const message = noticeProps.msgText(noticeEmail);
      noticeProps.msgText = message;
    }

    return {
      noticeType,
      ...noticeProps,
    };
  },
  {
    clearNotice: setNoticeType,
    checkVerifiedEmail,
  }
)(withRouter(Notice));
