import React from 'react';
import classNames from 'classnames';
import './h1.scss';

const H1 = ({
  text,
  className
}) => (
  <h1
    className={classNames('h1', {
      [className]: !!className,
    })}
  >
    {text}
  </h1>
);

export default H1;
