import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Profile from './profile';
import { setNoticeType } from '../../store/actions';

export default connect(
  null,
  {
    setNoticeType
  }
)(withRouter(Profile));
