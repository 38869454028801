import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  LOGIN_PATH,
  SIGNUP_PATH,
  TWO_FACTOR_PATH,
  VERIFY_AUTH_TIMEOUT,
  VERIFY_PATH
} from './constants';
import {
  deleteAuthorization,
  repopulateAuth,
  setBasicAxiosConfig,
} from './store/actions';

class Auth extends Component {
  constructor(props) {
    super(props);

    this.checkAuthIntervalId = null;
  }

  componentDidMount() {
    const {
      repopulateAuthAction,
      repopulateData,
      deleteAuthorizationAction,
      setBasicAxiosConfigAction,
      history,
    } = this.props;

    setBasicAxiosConfigAction(history);

    /* eslint-disable-next-line */
    const pathname = window.location.pathname;
    if (pathname === TWO_FACTOR_PATH) {
      deleteAuthorizationAction(() => history.push(LOGIN_PATH));
    } else {
      this.verifyAuth(repopulateAuthAction, repopulateData);
      this.checkAuthIntervalId = setInterval(this.verifyAuth, VERIFY_AUTH_TIMEOUT);
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthorized, history } = this.props;
    if (prevProps.isAuthorized && !isAuthorized) {
      history.push(LOGIN_PATH);
    }
  }

  componentWillUnmount() {
    this.checkAuthIntervalId && clearInterval(this.checkAuthIntervalId);
    this.checkAuthIntervalId = null;
  }

  checkHasAuthPath = () => {
    // eslint-disable-next-line
    const pathname = window.location.pathname;

    return [
      LOGIN_PATH,
      SIGNUP_PATH,
      TWO_FACTOR_PATH,
      VERIFY_PATH,
      `${VERIFY_PATH}/email`,
    ].includes(pathname);
  };

  verifyAuth = (repopulateAuthAction, repopulateData) => {
    const { deleteAuthorizationAction, history } = this.props;
    const expirationDate = window.localStorage.getItem('expirationDate');
    const token = window.localStorage.getItem('token');

    const unixTimestamp = Math.round(Date.now() / 1000);

    const hasNoCredentials = (expirationDate && unixTimestamp >= expirationDate) || !token;
    const hasAuthPath = this.checkHasAuthPath();

    if (hasNoCredentials && !hasAuthPath) {
      deleteAuthorizationAction(() => history.push(LOGIN_PATH));
    } else if (unixTimestamp < expirationDate && token) {
      repopulateAuthAction && repopulateAuthAction();
      repopulateData && repopulateData();
    }
  }

  render() {
    const { children, isAuthorized } = this.props;
    /* eslint-disable-next-line */
    const shouldShowComponent = isAuthorized || (!isAuthorized && this.checkHasAuthPath());

    return (
      <>
        {shouldShowComponent && children}
      </>
    );
  }
}

export default connect(
  state => ({
    isAuthorized: state.auth.isAuthorized,
  }),
  {
    deleteAuthorizationAction: deleteAuthorization,
    repopulateAuthAction: repopulateAuth,
    setBasicAxiosConfigAction: setBasicAxiosConfig,
  }
)(withRouter(Auth));
