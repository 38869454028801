import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TransactionDetails from './transaction-details';

export default connect(
  state => ({
    allTransactions: state.account.operations,
  })
)(withRouter(TransactionDetails));
