import React, { Component } from 'react';

import { Btn } from '../btn/btn';
import { FormItem } from '../form-item/form-item';
import { InputSelect } from '../input-select/input-select';
import { InputDesc } from '../input/input-desc';
import './form.scss';
import InputWithPlaceholder from '../input-with-placeholder';
import { INTRO_PATH } from '../../constants';

export default class RedeemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rublValue: '',
      fiatValue: '',
      fiatCurrency: '',
      accountNumber: '',
      bicNumber: '',
      validAmount: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { rate } = this.props;
    const { rublValue } = this.state;
    if (prevProps.rate !== rate) {
      this.handleRublChange(rublValue);
    }
  }

  handleRublChange = value => {
    const fiatValue = this.getFiatValue(value);
    this.setState({
      rublValue: value,
      fiatValue
    });
    this.validationAmount(value);
  };

  validationAmount = value => {
    const { balance } = this.props;
    if (value > balance || value === '0' || value === '0.') {
      this.setState({
        validAmount: false
      });
    } else {
      this.setState({
        validAmount: true
      });
    }
  };

  /* eslint-disable-next-line */
  getFiatValue = value => (value / this.props.rate).toFixed(2);

  handleFiatChange = value => {
    const { rate } = this.props;
    const rublValue = (value * rate).toFixed(2);
    this.validationAmount(rublValue);
    this.setState({
      fiatValue: value,
      rublValue,
    });
  };

  handleAccountNumberChange = value => {
    this.setState({
      accountNumber: value
    });
  };

  handleSelectionChange = fiatCurrency => {
    this.setState({ fiatCurrency });
    // eslint-disable-next-line
    this.props.fetchRates(fiatCurrency.code);
  }

  handleBicNumberChange = value => {
    this.setState({
      bicNumber: value
    });
  };

  handleSubmit = e => {
    e && e.preventDefault();
    const { redeem, history } = this.props;

    const {
      rublValue,
      fiatCurrency: { code },
      accountNumber,
      bicNumber
    } = this.state;

    redeem(rublValue, accountNumber, bicNumber, code, history);
  };

  render() {
    const {
      rublValue,
      fiatValue,
      accountNumber,
      bicNumber,
      validAmount
    } = this.state;
    const { currencies, balance } = this.props;

    const isDisabled = !rublValue || !balance || !accountNumber || !bicNumber || !validAmount;

    const secondTitle = `Balance ${balance} RUBL`;
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__block">
          <FormItem
            title="RUBL amount to sell"
            secondTitle={secondTitle}
            bottomError={!validAmount ? `Available only ${balance} RUBL` : ''}
          >
            <InputDesc
              name="rubl"
              placeholder="0"
              desc="RUBL"
              onChange={this.handleRublChange}
              value={rublValue}
              className="redeem-form-input"
              type="amount"
            />
          </FormItem>
        </div>

        <div className="form__block">
          <FormItem
            prompt
            item={(
              <InputSelect
                items={currencies}
                value={fiatValue}
                onChange={this.handleFiatChange}
                onSelectionChange={this.handleSelectionChange}
              />
            )}
            title="Fiat amount"
            // bottom="Min. amount to redeem is 850 000"
          />
        </div>

        <div className="form__block">
          <FormItem>
            <InputWithPlaceholder
              name="Account number / IBAN"
              placeholder="Account number / IBAN"
              type="text"
              onChange={this.handleAccountNumberChange}
            />
          </FormItem>
        </div>

        <div className="form__block">
          <FormItem>
            <InputWithPlaceholder
              name="SWIFT / BIC"
              placeholder="SWIFT / BIC"
              type="text"
              onChange={this.handleBicNumberChange}
            />
          </FormItem>
        </div>

        <div className="form__block">
          <FormItem>
            <InputWithPlaceholder
              name="Personal / organization name (Optional)"
              placeholder="Personal / organization name (Optional)"
              type="text"
              required={false}
            />
          </FormItem>
        </div>

        <div className="form__block">
          <Btn
            /* eslint-disable-next-line */
            text={!!rublValue ? `Sell ${rublValue} RUBL` : 'Sell RUBL'}
            mod="btn--lg btn--block"
            type="submit"
            disabled={isDisabled}
          />
        </div>
      </form>
    );
  }
}
