import React from 'react';
import { Logo } from '../logo/logo';
import LogoImgDark from '../logo/logo--dark.svg';
import './header-unauthorized.scss';

const unauthorizedNavigation = [
  {
    link: 'https://www.rubl.org',
    title: 'About',
  },
  {
    link: 'https://www.rubl.org', // TODO: change link
    title: 'Why use RUBL',
  },
  {
    link: '/about', // TODO: change link
    title: 'Fees',
  },
  {
    link: '/login', // TODO: change link
    title: 'Login',
  },
];

const HeaderUnauthorized = ({
  text,
}) => (
  <header className="header-authorization">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto">
          <Logo
            img={LogoImgDark}
            alt="Rubl"
          />
        </div>
      </div>
    </div>
  </header>
);

export default HeaderUnauthorized;
