import { connect } from 'react-redux';

import Deposit from './deposit';
import { setBackButtonVisibility } from '../../store/actions';

export default connect(
  state => ({
    address: state.account.address,
    addressQr: state.account.addressQr,
  }),
  {
    setBackButtonVisibility
  }
)(Deposit);
