import { connect } from 'react-redux';

import Balance from './balance';
import {
  fetchOperations,
  fetchRates
} from '../../store/actions';

export default connect(
  state => ({
    operations: state.account.operations,
  }),
  {
    fetchOperations,
    fetchRates
  }
)(Balance);
